import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html'
})
export class LogoComponent  implements OnInit{ 

@Input() ops= 1;
@Input() lg?:string;

constructor(){

}
  ngOnInit(): void {

  }

}
