import { Component, Input, OnInit } from '@angular/core';
import { NewsCPDTO, NewsIMG, NewsLine, Property } from 'src/app/app.models';
import { AppService } from '../../app.service';
import { NewsCP } from '../../app.models';
import { environment } from '../../../environments/environment.prod';
import { AuthServiceService } from 'src/app/auth-service.service';

export class Insta{
   
  constructor(
    public font?:string,
    public url?:string,
    public link?:string
  ){}
}

@Component({
  selector: 'app-circle',
  templateUrl: './circle.component.html',
  styleUrls: ['./circle.component.scss']
})
export class CircleComponent implements OnInit {

  public readonly INVALID:string = environment.valid;
  @Input() domain: string;
  @Input() news: NewsCPDTO;
  @Input() newsline: NewsIMG[] = [];
  @Input() viewType: string = "grid";
  @Input() midia: boolean = false; 
  @Input() viewColChanged: boolean = false; 
  @Input() fullWidthPage: boolean = true;   
  public column:number = 4;
  public array:Array<Insta> = [];
  public authUser: boolean = false;
  /**config skeleton */
  public skel = 
    { 
      'border-radius': '100px',
       width:'130px',
       height: '130px',
      'background-color': '#cccc',
       border: '1px solid white'
   };

  public slideConfig2 = {
    infinite: false,
    slidesToShow: 8,
    slidesToScroll: 8,
    autoplay:true,
    arrows: true, 
    speed: 300,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };
  public slideConfig = {
    dots: true,
    infinite: false,
    autoplay:true,
    arrows: true, 
    speed: 300,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };
  constructor( private accontService:AuthServiceService,public appervice:AppService) { }

  ngOnInit(): void {
    this.authUser = this.accontService.isUserLoggedIn(); 
     // this.appervice.getSocialMidia().subscribe(data => this.array = data);
  }

  /**
   * Remover cicle  ng-slix
   * @param fruit 
   * @param id 
   */
  remove(font: string,id:number): void {
    const index = this.array[id].font.indexOf(font);
    if (index >= 0) {
      this.array.splice(index, 1);
    }
  }

}
