import { Component, OnInit, Input, QueryList, ViewChildren } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { EmbedVideoService } from 'ngx-embed-video';
import { SwiperConfigInterface, SwiperDirective, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { filter, map, Subscription, Observable, BehaviorSubject } from 'rxjs';

import { AppService } from 'src/app/app.service';
import { AuthServiceService } from 'src/app/auth-service.service';
import { NewsCPDTO, videoMedia } from '../../app.models';


@Component({
  selector: 'app-news-video',
  templateUrl: './news-video.component.html',
  styleUrls: ['./news-video.component.scss']
})
export class NewsVideoComponent implements OnInit {
  
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };

  public authUser:boolean = false;
  public user:number=null;
  public watcher: Subscription;
  @ViewChildren(SwiperDirective) swipers: QueryList<SwiperDirective>;
  public config: SwiperConfigInterface = { };

  @Input() viewType:string = 'grid';
  @Input() viewCol: number = 25;
  @Input() news:NewsCPDTO;

  public embedVideo:Array<string> = [];
  public videos:any = [];
  public size: number = 5;

  /**OBS */
  public domain:string="axl";


  /**Cache */
  public cache:BehaviorSubject<any> = new BehaviorSubject(null); 

  constructor(  
    public mediaObserver: MediaObserver,
    private embedService: EmbedVideoService,
    public accontService:AuthServiceService,
    public appService:AppService) {
      this.watcher = mediaObserver.asObservable()
      .pipe(filter((changes: MediaChange[]) => changes.length > 0), map((changes: MediaChange[]) => changes[0]))
      .subscribe((change: MediaChange) => {
        // console.log(change)
        if(change.mqAlias == 'xs') {
          this.viewCol = 100;
        }
        else if(change.mqAlias == 'sm'){
          this.viewCol = 50;
        }
        else if(change.mqAlias == 'md'){
          this.viewCol = 33.3;
        }
        else{
          this.viewCol = 25;
        }
      });
    }

  ngOnInit(): void {
    this.authUser = this.accontService.isUserLoggedIn();  
    this.user =  this.authUser === true ?  this.appService.getUser() : null;
    this.getVideos();
  }

/**Video embed */
public getVideos(){
  if(this.cache.getValue()  != null){
    this.videos =  new Observable<videoMedia[]>((response) =>{
    response.next(this.cache.getValue());
   });
  }else{
   this.appService.getNewsVideos(this.size)
     .subscribe(data => {
       this.cache.next(data);
       this.videos  =  this.cache.getValue();
       for (let index = 0; index < this.videos.length; index++) {
        let bed = this.embedService.embed(this.videos[index].url);
        this.embedVideo.push(bed);
       }
       setTimeout(() => { 
         this.config.observer = true;
         this.swipers.forEach(swiper => { 
           if(swiper){
             swiper.setIndex(0);
           } 
         }); 
       });
      });
    }
 }

 ngAfterViewInit(){
  this.config = {
    observer: true,
    slidesPerView: 1,
    spaceBetween: 0,       
    keyboard: true,
    navigation: true,
    pagination: this.pagination,
    grabCursor: true,        
    loop: false,
    preloadImages: false,
    lazy: true,  
    breakpoints: {
    480: {
         slidesPerView: 1
    },
    740: {
         slidesPerView: 2,
    },
    960: {
         slidesPerView: 3,
    }
   }
  }
}
}


