import { Columns } from './../../app.models';
import { Component, Input, OnInit } from '@angular/core';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { AppService } from 'src/app/app.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs';
import { error } from 'console';
import { Tile } from '../manchete/manchete.component';

@Component({
  selector: 'app-our-agents',
  templateUrl: './our-agents.component.html',
  styleUrls: ['./our-agents.component.scss']
})
export class OurAgentsComponent implements OnInit {
  
  @Input() domain?:string;
  public config: SwiperConfigInterface = { };
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };

/**Cache */
public cache:BehaviorSubject<any> = new BehaviorSubject(null); 
public colums:Columns[] | Observable<Columns[]> = [];
  error: string;

  public tiles: Tile[] = [
    {text: 'One', cols: 2, rows:2, color: 'lightblue'},
    {text: 'Two', cols: 1, rows: 2, color: 'lightgreen'},
    {text: 'Four', cols: 1, rows: 2, color: '#DDBDF1'}
  ];


  constructor(public appService:AppService) { }

  ngOnInit() {
    if(this.cache.getValue()  != null){
      this.colums =  new Observable<Columns[]>((response) =>{
          response.next(this.cache.getValue());
      });
    }else{
     this.appService.getColumnsDomain(this.domain).subscribe(data => {
       this.cache.next(data);
       this.colums = this.cache.getValue();
      },error => {
         
           this.error = "Erro Nenhum registro econtrado"
      });
    }
  }

  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 1,
      spaceBetween: 0,       
      keyboard: true,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: false,  
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      speed: 500,
      effect: "slide",
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2
        },
        960: {
          slidesPerView: 3
        }
      }
    }
  }

}
