import { Component, Input, OnInit } from '@angular/core';
import { Property } from 'src/app/app.models';
import { AppService } from 'src/app/app.service';
import { NewsCP, NewsCPDTO } from '../../app.models';

@Component({
  selector: 'app-comment-circle',
  templateUrl: './comment-circle.component.html',
  styleUrls: ['./comment-circle.component.scss']
})
export class CommentCircleComponent implements OnInit {

  @Input() property: Property;
  @Input() viewType: string = "grid";
  @Input() midia: boolean = false; 
  @Input() viewColChanged: boolean = false; 
  @Input() fullWidthPage: boolean = true;   
  @Input() sm: boolean = false; 
  public column:number = 4;
  public panelOpenState = false;
  public date:Date = new Date();
  public new:NewsCPDTO;

  constructor( public appService:AppService){
    
  }
  
  ngOnInit(): void {
   
  }
   public array = [
    {
      "font": "Foxnews",
      "url": "../../../assets/images/instagram/foxnews.jpg",
      "link": "https://www.instagram.com/foxnews/",
    },
    {
      "font": "awj",
      "url": "../../../assets/images/instagram/wsj.jpg",
      "link":"https://www.instagram.com/wall.street.journal/",
    },
    {
      "font": "caesecia.pet",
      "url": "../../../assets/images/instagram/caescia.jpg",
      "link": "https://www.instagram.com/caesecia.pet/",
    },
    {
      "font": "Jpost",
      "url": "../../../assets/images/instagram/jpost.jpg",
      "link": "https://www.instagram.com/thejerusalem_post/",
    },
    {
      "font": "Trump",
      "url": "../../../assets/images/instagram/trump.jpg",
      "link": "https://www.instagram.com/realdonaldtrump/",
    },
    {
      "font": "Foxnews",
      "url": "../../../assets/images/instagram/tmz.jpg",
      "link": "https://www.instagram.com/tmz_tv/",
    },
    {
      "font": "dailymail",
      "url": "../../../assets/images/instagram/daily.jpg",
      "link": "https://www.instagram.com/dailymail/",
    },
    {
      "font": "borisjohnsonuk",
      "url": "../../../assets/images/instagram/boris.jpg",
      "link": "https://www.instagram.com/borisjohnsonuk/",
    },
    {
      "font": "jairmessiasbolsonaro",
      "url": "../../../assets/images/instagram/bolsonaro.jpg",
      "link": "https://www.instagram.com/jairmessiasbolsonaro",
    },
    {
      "font": "forbesbr",
      "url": "../../../assets/images/instagram/forbs.jpg",
      "link": "https://www.instagram.com/forbesbr/",
    },
    {
      "font": "adorocinema",
      "url": "../../../assets/images/instagram/adoro.jpg",
      "link": "https://www.instagram.com/adorocinema/",
    },
  
    {
      "font": "caesecia.pet",
      "url": "../../../assets/images/instagram/caescia.jpg",
      "link": "https://www.instagram.com/caesecia.pet/",
    },
 
   
];
  public slideConfig2 = {
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay:true,
    arrows: true, 
    speed: 300,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          infinite: true,
       
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };
}
