import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
   name:string;
   content:string;
   date:Date;
}

@Component({
  selector: 'app-dialog-sm',
  templateUrl: './dialog-sm.component.html',
  styleUrls: ['./dialog-sm.component.scss']
})
export class DialogSmComponent  {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,public dialogRef: MatDialogRef<DialogSmComponent>) {}

  
}
