import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier/lib/services/notifier.service';
import { UserData } from 'src/app/app.models';
import { AppService } from 'src/app/app.service';
import { AuthServiceService } from 'src/app/auth-service.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  @Input() domain:string;
  
  public user:UserData = new UserData();
  public readonly default:string = "Visitante";
  public authUser: boolean = false;
  private readonly notifier: NotifierService;

  constructor(
    public router:Router,
    public appService:AppService,
    public accontService:AuthServiceService,
    ) { 
     // this.notifier = notifierService;
    }

  ngOnInit() {
    this.authUser = this.accontService.isUserLoggedIn();
    if(this.authUser === true){
      this.appService.getByUser(this.appService.getUser()).subscribe(data => this.user = data)
    }
  }

  
 /**
  * Limpar todas as sessão 
  * @returns void
  */
  public logout():void{
    sessionStorage.clear();
    //this.showNotification("success","Sair 😁");
    setTimeout(() => {
      this.router.navigate(["/home/"+this.domain]);
    }, 100);
  }

  	/**
	 * Show a notification
	 *
	 * @param {string} type    Notification type
	 * @param {string} message Notification message
	 */
     public showNotification( type: string, message: string ): void {
      this.notifier.notify( type, message);
    }
  

}
