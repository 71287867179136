import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SwiperDirective, SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { Filters, NewsCPDTO, PageSize} from 'src/app/app.models';
import { AppService} from 'src/app/app.service';
import { AppSettings, Settings } from 'src/app/app.settings';
import { CompareOverviewComponent } from '../compare-overview/compare-overview.component';
import { NewsLine } from '../../app.models';
import { environment } from '../../../environments/environment.prod';
import { BehaviorSubject, Observable, map } from 'rxjs';

declare var $:any;


export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'app-manchete',
  templateUrl: './manchete.component.html',
  styleUrls: ['./manchete.component.scss']
})
export class MancheteComponent implements OnInit {
  
  public readonly INVALID:string = environment.valid;
  
  @Input() property: NewsCPDTO;
  @Input() newsline:NewsLine;
  @Input() newsAll: Array<NewsCPDTO>;
  @Input() grids: number;
  @Input() viewType: string = "grid";
  @Input() viewColChanged: boolean = false; 
  @Input() fullWidthPage: boolean = true;   
  @Input() domain: string;   

  public column:number = 4;
  public menuItems: any[];
  tiles: Tile[] = [
    {text: 'One', cols: 2, rows:4, color: 'lightblue'},
    {text: 'Two', cols: 1, rows: 2, color: 'lightgreen'},
    {text: 'Four', cols: 1, rows: 2, color: '#DDBDF1'},
    {text: 'Three', cols: 1, rows: 2, color: 'lightpink'},
    {text: '5', cols: 1, rows: 2, color: 'lightpink'},
  ];

  /*Cores dinamicas de acordo com a categoria  */
  genreColors: { [key: string]: string } = {
    "SPORT": "green",
    "POLITIC": "red",
    "AUTOMOBILES": "blue",
    "CINEMA": "purple", 
    "MUSIC": "pink",    
    "HEALTH": "orange",
    "SCIENCE": "cyan",  
    "GAME": "yellow",  
    "CELEBRITIES": "magenta",
    "SEVERAL": "brown"  
  };
  
  /**config skeleton */
  public skel = 
    { 
      'border-radius': '5px',
       width:'150px',
       height: '10px',
      'background-color': '#cccc',
       border: '1px solid white'
   };
  
  public slideConfig2 = {
    slidesToShow: 3, // Defina o número de slides a serem exibidos
    slidesToScroll: 1,
    infinite: true,
    dots: false,
    arrows: true,
    prevArrow: '<button  type="button" class="slick-prev">&#10094;</button>',
    nextArrow: '<button type="button" class="slick-next">&#10095;</button>',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          infinite: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          infinite: true,
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };
  public array = [
    {"width":20},
    {"width":20},
    {"width":5},
    {"width":5},
    {"width":5},
    {"width":5},
    {"width":2},
    {"width":2},
    {"width":2},
    {"width":2},
    {"width":2},
    {"width":2},
    {"width":2},
    {"width":2},
  ];

  // public address:string; 
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  public config: SwiperConfigInterface = {};
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };
  public settings: Settings;

  /** OBJETO NEWS */
  public news_recets:(NewsCPDTO | Observable<NewsCPDTO>);
  /**Cache */
  public cache:BehaviorSubject<any> = new BehaviorSubject(null); 
  private readonly days: number= environment.days;
  /**PAgination-size */
  public readonly SIZE:number =  environment.pagination;

  
  constructor(public appSettings:AppSettings, public appService:AppService) {
    this.settings = this.appSettings.settings;
    this.menuItems = this.appService.ROUTES.filter(menuItem => menuItem);
  
  }

  ngOnInit(): void {
      this.getAll();/**Manchete*/
  }


 /**
  * Noticias recentes da manchete
  * @return void
  */
 public getAll():void{
  let filt = new Filters(null,this.days);
  if(this.cache.getValue()  != null){
    this.news_recets  =  new Observable<NewsCPDTO>((response) =>{
        response.next(this.cache.getValue());
    });
  }else{
   this.appService.getNewsManchete_redux(this.domain,filt.days,"SEVERAL",new PageSize((10),0))
  .pipe(map(data => {
    this.cache.next(data);
    this.news_recets   =  this.cache.getValue();
  })).subscribe();
  }
 }

  ngAfterViewInit(){
    this.initCarousel();
    
  }
  public getStatusBgColor(status){
    switch (status) {
      case 1:
        return '#558B2F';  
      case 2:
        return '#1E88E5'; 
      case 3:
        return '#009688';
      case 4:
        return '#FFA000';
      case 5:
        return '#F44336';
      case 6:
        return '#000';
      default: 
        return '#01579B';
    }
  }


 
  public initCarousel(){
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,         
      keyboard: false,
      navigation: true,
      pagination: this.pagination,
      grabCursor:false,        
      loop: false,
      preloadImages: false,
      lazy: true,  
      nested: false,
      // autoplay: {
      //   delay: 5000,
      //   disableOnInteraction: false
      // },
      speed: 500,
      effect: "slide"
    }
  }
  
  public addToCompare(){
    this.appService.addToCompare(this.property[0], CompareOverviewComponent, (this.settings.rtl) ? 'rtl':'ltr'); 
  }

  public onCompare(){
    return this.appService.Data.compareList.filter(item=>item.id == this.property[0].id)[0];
  }

  public addToFavorites(){
    this.appService.addToFavorites(this.property[0], (this.settings.rtl) ? 'rtl':'ltr');
  }

  public onFavorites(){
   /// return this.appService.Data.favorites.filter(item=>item.id == this.property[0].id)[0];
  }
  
}
