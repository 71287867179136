import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dates'
})
export class DatesPipe implements PipeTransform {

  transform(date?:any): any {
      let dt  = new Date(date[0],date[1],date[2]);
      return dt;
   }
  
}
