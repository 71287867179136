import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  private readonly name:string = "session";
 
  constructor() { }


    /**
     * Pegar token de JWT
     * @returns any
     */
     public getAuthorizationToken(){
      const token = JSON.parse(sessionStorage.getItem(this.name));
      try{
        return token.pass.token;
      }catch(erro){
        return null;
      }
    }
    
  
    /**
     * Obter data do token em UTC
     * @param token 
     * @returns 
     */
      public getTokenExpiration(token:string):Date{
      
        const decoded:any = jwt_decode(token);
      
       if(decoded.exp === undefined){
          return null;
       }
       
       const date = new Date(0);
       date.setUTCSeconds(decoded.exp);
       return date;
  
      }
  
  
      /**
       * Verificar expiração do token
       * @param token 
       */
     public isTokenExpired(token?:string):boolean{
       if(!token){
          return true;
        }
  
        const date = this.getTokenExpiration(token);
        if(date === undefined){
          return false;
        }
  
        return !(date.valueOf() > new Date().valueOf());
     }
  
   /**
    * Verificar usuario autenticado
    */
     public isUserLoggedIn(){
      const token  = this.getAuthorizationToken();
      if(!token){
        return false;
      }else if(this.isTokenExpired(token)){
        return false;
      }
      return true;
     }

}
