import { Component, Input, OnInit } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { map, Subscription } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { AppSettings, Settings } from 'src/app/app.settings';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-pdf-card',
  templateUrl: './pdf-card.component.html',
  styleUrls: ['./pdf-card.component.scss']
})
export class PdfCardComponent implements OnInit {
  
  @Input() domain: string;
  @Input() viewType: string = "grid";
  @Input() viewColChanged: boolean = false; 
  @Input() fullWidthPage: boolean = true;   
  public grids: number = 4;
  public settings: Settings;
  watcher: Subscription;
  viewCol: number;

  constructor(public appSettings:AppSettings, public appService:AppService, public mediaObserver: MediaObserver) {
    this.settings = this.appSettings.settings;

    this.watcher = mediaObserver.asObservable()
    .pipe(filter((changes: MediaChange[]) => changes.length > 0), map((changes: MediaChange[]) => changes[0]))
    .subscribe((change: MediaChange) => {
      // console.log(change)
      if(change.mqAlias == 'xs') {
        this.viewCol = 100;
      
      }
      else if(change.mqAlias == 'sm'){
        this.viewCol = 50;
      
      }
      else if(change.mqAlias == 'md'){
        this.viewCol = 33.3;
     
      }
      else{
        this.viewCol = 50;
       
      }
    });

  }

  ngOnInit(): void {
  }

}


