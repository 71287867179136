import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterByIdPipe } from './filter-by-id.pipe';
import { FilterNeighborhoodsPipe } from './filter-neighborhoods';
import { FilterStreetsPipe } from './filter-streets.pipe';
import { DateTimeFormatPipe } from './DateTimeFormatPipe';
import { DatesPipe } from './dates';
import { SafePipe } from './safe';

@NgModule({
    imports: [ 
        CommonModule, 
    ],
    declarations: [
        FilterByIdPipe,
        FilterNeighborhoodsPipe,
        FilterStreetsPipe,
        DateTimeFormatPipe,
        DatesPipe,
        SafePipe,
        
    ],
    exports: [
        FilterByIdPipe,
        FilterNeighborhoodsPipe,
        FilterStreetsPipe,
        DateTimeFormatPipe,
        DatesPipe,
        SafePipe
    ]
})
export class PipesModule { }
