import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { AuthServiceService } from 'src/app/auth-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-toolbar1',
  templateUrl: './toolbar1.component.html'
})
export class Toolbar1Component implements OnInit {
  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>();
  public authUser: boolean = false;
  domain: string;
  public readonly TIME: number = environment.domain.time;

  constructor(public appService:AppService,
    public accontService:AuthServiceService) { 

  }
   

  ngOnInit() { 
    this.authUser = this.accontService.isUserLoggedIn(); 
    const url = window.location.href;
    this.domain = this.appService.getUrl(decodeURIComponent(url));
  }

  public sidenavToggle(){
    this.onMenuIconClick.emit();
  }
}

