import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { AppService } from 'src/app/app.service';
import { Data } from '../../app.service';
import { ColumnsComment } from '../../app.models';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit { 
  public config: SwiperConfigInterface = { };
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };

  /**Cache */
  public cache:BehaviorSubject<any> = new BehaviorSubject(null); 

  public comments:ColumnsComment | Observable<ColumnsComment> = new ColumnsComment();
  constructor(public appService:AppService) { }

  ngOnInit() {
    /**
     *  if(this.domain == null || this.domain == undefined){
      const session = this.service.loadSession();
      this.domain = session.dm;
    }
     */
    if(this.cache.getValue()  != null){
      this.comments   =  new Observable<ColumnsComment>((response) =>{
          response.next(this.cache.getValue());
     });
    }else{
    this.appService.getCommentColumns()
    .subscribe(data => {
      this.cache.next(data);
      this.comments = this.cache.getValue();
    });
   }
  }

  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 1,
      spaceBetween: 0,       
      keyboard: true,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true,  
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      speed: 500,
      effect: "slide",
      breakpoints: {
      480: {
           slidesPerView: 1
      },
      740: {
           slidesPerView: 2,
      },
      960: {
           slidesPerView: 3,
      }
     }
    }
  }

}
