import { Component, OnInit, Input } from '@angular/core';
import { AddressPV } from 'src/app/app.models';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

  @Input() domain:string;
  @Input() dividers:boolean = true;
  @Input() iconSize:string = 'sm';
  @Input() iconColor:string = '';

  public ed:AddressPV = new AddressPV();
  public readonly TIME:number = environment.domain.time;
  
  constructor(public appService:AppService) { }

 /**config skeleton */
 public skel = 
 { 
   'border-radius': '5px',
    width:'300px',
    height: '300px',
    'background-color': 'rgb(215 215 215)', 
    border: '1px solid white'
};

  ngOnInit() {
    const session = this.appService.loadSession();
    this.domain = session.dm;
    if(this.domain == '' || this.domain == null){
      const url = window.location.href;
      this.domain = this.appService.getUrl(decodeURIComponent(url));
    }
    this.appService.anddressProvedorDomain(this.domain).subscribe(data => {
      this.ed = data
    });
  }

}
