import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { CommentCreateUser, CommentVideo } from 'src/app/app.models';
import { emailValidator } from 'src/app/theme/utils/app-validators';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {

  public cm:CommentCreateUser;
  private readonly notifier: NotifierService;
  private  success: string = "";
  public  nortifys: boolean = false;

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };


  @Input('auth')  authUser:boolean;
  @Input('title') title:string;
  @Input('comments') reviews:(CommentVideo[]);
  @Input('propertyId') propertyId;
  @Input('ops') ops:number = 1;
  @Input('idvideo') idvideo:number;
  @Input('iduser') iduser:number;

  public commentForm: FormGroup;
  public config: SwiperConfigInterface = { };

  public ratings = [
    { title: 'Very Dissatisfied', icon: 'sentiment_very_dissatisfied', percentage: 20, selected: false },
    { title: 'Dissatisfied', icon: 'sentiment_dissatisfied', percentage: 40, selected: false },
    { title: 'Neutral', icon: 'sentiment_neutral', percentage: 60, selected: false },
    { title: 'Satisfied', icon: 'sentiment_satisfied', percentage: 80, selected: false },
    { title: 'Very Satisfied', icon: 'sentiment_very_satisfied', percentage: 100, selected: false }
  ];



 
  constructor(public fb: FormBuilder,
    public Appservice:AppService,
    public notifierService: NotifierService) {
      this.notifier = notifierService;
      this.cm = new CommentCreateUser();
     }

     ngAfterViewInit(){
      this.config = {
        observer: true,
        slidesPerView: 5,
        spaceBetween: 0,       
        grabCursor: true,        
        loop: false,
        preloadImages: false,
        lazy: true,  
        breakpoints: {
        480: {
             slidesPerView: 1
        },
        740: {
             slidesPerView: 4,
        },
        960: {
             slidesPerView: 4,
        }
       }
      }
    }

  ngOnInit() {
    this.commentForm = this.fb.group({ 
      review: [null, Validators.required],            
      name: [null, Validators.compose([Validators.required, Validators.minLength(4)])],
      email: [null, Validators.compose([Validators.required, emailValidator])],
      rate: null,
      subject:[null, Validators.compose([Validators.required, Validators.minLength(4)])],
      propertyId: this.propertyId
    }); 
  }

	/**
	 * Show a notification
	 *
	 * @param {string} type    Notification type
	 * @param {string} message Notification message
	 */
   public showNotification( type: string, message: string ): void {
		this.notifier.notify( type, message + " 👏");
	}


  /**Criar comentário do usuario na sessão de video
   * Comentarios
   * @param values 
   */
  public onCommentFormSubmit(values:CommentCreateUser){
    if (this.commentForm.valid) { 
      if(values.rate){
        this.cm.rate = values.rate;
        this.cm.idnews = this.idvideo;
        this.cm.iduser = this.iduser;
        this.cm.iduser = this.iduser;
        this.cm.icon = this.ratings.filter(r => r.percentage == values.rate)[0].icon;
        this.Appservice.createCommentVideo(this.cm,this.nortifys)
        .subscribe(data => {
          this.success = "O seu comentario foi criado com sucesso",
          this.showNotification("success",this.success);
        });
        
         //this.onClickUpdate();
      }     
    } 
  }

  public rate(rating:any){
    this.ratings.filter(r => r.selected = false);
    this.ratings.filter(r => r.percentage == rating.percentage)[0].selected = true;
    this.commentForm.controls.rate.setValue(rating.percentage);
  }

}