import { Component, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { Settings, AppSettings } from './app.settings';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { AuthConfig, ConfigAuth, Main } from './app.models';
import { AppService } from './app.service';
import { environment } from 'src/environments/environment';

declare var $:any;
declare var gtag:any;
declare var window:any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit{

  private readonly gtas:any = JSON.parse(environment.API); 
  
  public settings: Settings;
  constructor(public AppService:AppService, 
              public appSettings:AppSettings, 
              private route: ActivatedRoute,
              public router:Router, 
              @Inject(PLATFORM_ID) private platformId: Object,
              public translate: TranslateService){
                
    const url = window.location.href;
    this.AppService.domain_ = this.getUrl(decodeURIComponent(url));/*Pegar dominio*/
    this.settings = this.appSettings.settings;
    translate.addLangs(['en','de','fr','ru','tr','pt']);
    translate.setDefaultLang('pt'); 
    translate.use('pt');
    this.isFirstSession();
  }


  /**
   * 
   * @param url 
   */
  private getUrl(url){
    
      let domain ="";

      
    // Encontrar a posição do primeiro ponto de interrogação na URL
    let questionMarkIndex = url.indexOf('?');
    if (questionMarkIndex !== -1) {
        // Se houver um ponto de interrogação, pegar a substring após ele
        let queryString = url.substring(questionMarkIndex + 1);

        // Dividir a string de consulta em pares chave-valor
        let params = new URLSearchParams(queryString);
        
        // Verificar se 'dm' está presente e pegar o valor correspondente
        if (params.has('dm')) {
            domain = params.get('dm');
        }
    } else {
        // Se não houver ponto de interrogação, o domínio é toda a substring após a última barra
        let lastSlashIndex = url.lastIndexOf('/');
        if (lastSlashIndex !== -1) {
            domain = url.substring(lastSlashIndex + 1);
        }
    }

    domain = this.separate_url(domain);

    return domain;

  }

  /**
   * Separar url de dominio
   */
  private separate_url(domain){

   // Verificar se 'dm=' está presente na string do domínio
    let dmIndex = domain.indexOf('dm=');
    if (dmIndex !== -1) {
        // Se 'dm=' está presente, pegar o valor após 'dm='
        domain = domain.substring(dmIndex + 3);
    }

    // Verificar se há um '?' na string do domínio
    let questionIndex = domain.indexOf('?');
    if (questionIndex !== -1) {
        // Se houver um '?', pegar a substring antes dele
        domain = domain.substring(0, questionIndex);
    }

    return domain;

  }

 /**
  * Anuncios
  * @returns 
  */
  private isFirstSession(): boolean {
    const isFirstSession = sessionStorage.getItem('firstSession');
    if (!isFirstSession) {
      sessionStorage.setItem('firstSession', 'true');
      return true;
    }
    return false;
  }

  /**
   * Google ads & analitics
   */
  ngOnInit(): void {
    this.getGoogleAds();
  }

  ngAfterViewInit(){ 
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {   
        setTimeout(() => {
          if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0,0);
          }
        }); 
      }            
    });    
  }



/**Google ADS & Analicts*/
public getGoogleAds(){
  /**google Ads */
  const navEndEvents$ = this.router.events
  .pipe(
    filter(event => event instanceof NavigationEnd)
  );
  navEndEvents$.subscribe((event: NavigationEnd) => {
    gtag('config', environment.google , {
      'page_path': event.urlAfterRedirects
    });
  });
}


}
