import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommentCOMPLETE, CommentCreateUser, CommentVideo } from 'src/app/app.models';
import { emailValidator } from 'src/app/theme/utils/app-validators';
import { CommentNews, NewsCPDTO } from '../../app.models';
import { AppService } from '../../app.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-comment-news',
  templateUrl: './comment-news.component.html',
  styleUrls: ['./comment-news.component.scss']
})
export class CommentNewsComponent implements OnInit {

 
  public cm =  new CommentCreateUser();
  public nortify:boolean = false;

  @Input('title') title:string;
  @Output("event") rangerComment: EventEmitter<any> = new EventEmitter<any>();
  @Input('idnews') idnews:number;
  @Input('iduser') iduser:number;
  @Input('comments') reviews: CommentCOMPLETE[];
  @Input('propertyId') propertyId;
  @Input('ops') ops:number = 1;
  @Input('disabled') disabled:boolean = true;
  public commentForm: FormGroup;
  private readonly notifier: NotifierService;

  public ratings = [
    { title: 'Very Dissatisfied', icon: 'sentiment_very_dissatisfied', percentage: 20, selected: false },
    { title: 'Dissatisfied', icon: 'sentiment_dissatisfied', percentage: 40, selected: false },
    { title: 'Neutral', icon: 'sentiment_neutral', percentage: 60, selected: false },
    { title: 'Satisfied', icon: 'sentiment_satisfied', percentage: 80, selected: false },
    { title: 'Very Satisfied', icon: 'sentiment_very_satisfied', percentage: 100, selected: false }
  ];
  public success: string;
  constructor(public fb: FormBuilder,
    public appservice:AppService,
    public notifierService: NotifierService) {
      this.notifier = notifierService;
     }

  ngOnInit() {
    this.commentForm = this.fb.group({ 
      review: [null, Validators.required],            
      name: [null, Validators.compose([Validators.required, Validators.minLength(4)])],
      subject: [null, Validators.compose([Validators.required, Validators.minLength(4)])],
      email: [null, Validators.compose([Validators.required, emailValidator])],
      rate: null,
      nortify: null,
      propertyId: this.propertyId
    }); 
  }


  
/**
 * Emitter
 */
  public onClickUpdate():void{
     this.rangerComment.emit();
  }
  
	/**
	 * Show a notification
	 *
	 * @param {string} type    Notification type
	 * @param {string} message Notification message
	 */
   public showNotification( type: string, message: string ): void {
		this.notifier.notify( type, message );
	}



/**Criar
   * Comentarios (POST)
   * @param values 
   */
 public onCommentFormSubmit(values:CommentCreateUser){
  if (this.commentForm.valid) { 
    if(values != undefined){
       this.cm.rate = values.rate;
       this.cm.idnews = this.idnews;
       this.cm.iduser = this.iduser;
       this.appservice.createComment(this.cm,this.nortify)
       .subscribe(data => {
         this.success = "O seu comentario foi criado com sucesso",
         this.showNotification("success",this.success);
       });
        this.onClickUpdate();
      //property.ratingsCount++,
      //property.ratingsValue = property.ratingsValue + values.rate,
    }     
  } 
}

public rate(rating:any){
  this.ratings.filter(r => r.selected = false);
  this.ratings.filter(r => r.percentage == rating.percentage)[0].selected = true;
  this.commentForm.controls.rate.setValue(rating.percentage);
}

}
