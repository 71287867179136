import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpParameterCodec } from '@angular/common/http';
import { Observable, ReplaySubject, BehaviorSubject } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Property, Location, NewsCPDTO, About, Publicity, UserData, Shopping, AuthConfig, Login, videoMedia, Coluns, NewsCPCOMPLET, Filters, NewsLine, AddressPV, Columns, ColumnsComment, Adverts, Faqs, CommentNews, PageSize, CommentCreateUser, Source, NewsCP, Vehicle, ContactVH, VehicleCT, Vehicle_Redux, SendEmail, NewsIMG, DataIa, ResponseIA, Vehicle_User } from './app.models';
import { AppSettings } from './app.settings';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from './shared/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from './shared/alert-dialog/alert-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Insta } from './shared/circle/circle.component';
import { error } from 'console';

// Definindo uma interface para o objeto de sessão
interface SessionData {
  dm: string;
}

export class Data {
  constructor(public properties:NewsCPDTO[],
              public compareList: Property[],
              public favorites: NewsCP[],
              public locations: Location[]
              ) { }

}
declare interface RouteInfo {
  id: number;
  title: string;
  icon: string;
  class: string;
  routerLink: string;
  href: string;
  target: string;
  hasSubMenu: boolean;
  parentId: number;
}


@Injectable({
  providedIn: 'root'
})
export class AppService implements HttpParameterCodec {
  public Data = new Data(
    [], // properties
    [], // compareList
    [], // favorites
    []  // locations
  )

  /**Spiker */
  public voices:any=[];

  public authUser:AuthConfig = new AuthConfig();
  public readonly API_KEY = `${environment.API}`;
  public readonly GENRES:string[] = environment.genres;
 // public readonly TYPEGENRE:typeNews = environment.genreType;

  public apiKey = 'AIzaSyAO7Mg2Cs1qzo_3jkKkZAKY6jtwIlm41-I';
  public readonly url1 = environment.serve + "/api";
  public url = environment.url + '/assets/data/'; 
  
  public domain_:string;  
  public ROUTES: RouteInfo[] = [];
  
  constructor(public http:HttpClient, 
              private bottomSheet: MatBottomSheet, 
              private snackBar: MatSnackBar,
              public appSettings:AppSettings,
              public dialog: MatDialog,
              public translateService: TranslateService,
              @Inject(PLATFORM_ID) private platformId: Object) {
                this.authUser = JSON.parse(this.API_KEY);//Abri session auth

                this.ROUTES= [
                  { id:0,title: 'MAIN_NEW.SPORT',  icon: 'today', class: '', routerLink:"genre/SPORT/dm",href:"",target:"",hasSubMenu:true,parentId:1 },
                  { id:1,title: 'MAIN_NEW.HEALTH',  icon: 'dashboard', class: '',routerLink:"genre/HEALTH/dm",href:"",target:"",hasSubMenu:true,parentId:2 },
                  { id:2,title: 'MAIN_NEW.POLITIC',  icon: 'contacts', class: '',routerLink:"genre/POLITIC/dm",href:"",target:"",hasSubMenu:true,parentId:3 },
                  { id:3,title: 'MAIN_NEW.GAME',  icon: 'support_agent', class: '',routerLink:"genre/GAME/dm",href:"",target:"",hasSubMenu:true,parentId:4 },
                  { id:4,title: 'MAIN_NEW.SCIENCE',  icon: 'railway_alert', class: '',routerLink:"genre/SCIENCE/dm",href:"",target:"",hasSubMenu:true,parentId:5 },
                  { id:5,title: 'MAIN_NEW.CINEMA',  icon: 'calculate', class: '' ,routerLink:"genre/CINEMA/dm",href:"",target:"",hasSubMenu:true,parentId:6 },
                  { id:6,title: 'MAIN_NEW.INTERNATIONAL',  icon: 'groups', class: '',routerLink:"internacional",href:"",target:"",hasSubMenu:true,parentId:7  },
                  { id:7,title: 'MAIN_NEW.CELEBRITIES',  icon: 'segment', class: '',routerLink:"genre/AUTOMOBILES/dm",href:"",target:"",hasSubMenu:true,parentId:8  },
                  { id:8,title: 'MAIN_NEW.AUTOMOBILES',  icon: 'segment', class: '',routerLink:"genre/CELEBRITIES/dm",href:"",target:"",hasSubMenu:true,parentId:9  },
                  { id:9,title: 'MAIN_NEW.ECONOMY',  icon: 'support_agent', class: '',routerLink:"genre/ECONOMY/dm",href:"",target:"",hasSubMenu:true,parentId:10 }
                ];
              
            }



  /**SET domain */
  public setDomain(domain:string):void{
    console.log('Setando o domínio:', domain);
     this.domain_ = domain;
  }

  public getDomain(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.domain_ !== undefined) {
        resolve(this.domain_);
      } else {
        reject('Valor não definido');
      }
    });
  }

// Função para verificar e carregar a sessão
public loadSession(): SessionData {
  // Verifica se há uma sessão existente
  const sessionStr = localStorage.getItem('se_dm');
  // Se a sessão existir, sobrescreva seu valor
  if (sessionStr) {
    const novaSessionData: SessionData = JSON.parse(sessionStr);
    novaSessionData.dm = this.domain_; // Sobrescreve o valor de 'dm'
    // Salva a nova sessão
    localStorage.setItem('se_dm', JSON.stringify(novaSessionData));
    // Retorna a nova sessão
    return novaSessionData;
  }else {
    // Se não houver uma sessão existente, cria uma nova e a retorna
    const newSession: SessionData = { dm: this.domain_ };
    localStorage.setItem('se_dm', JSON.stringify(newSession));
    return newSession;
  }
}

  encodeKey(key: string): string {
    throw new Error('Method not implemented.');
  }

  encodeValue(value: string): string {
     return value.replace("+","%2B");
  }

  decodeKey(key: string): string {
    throw new Error('Method not implemented.');
  }

  decodeValue(value: string): string {
    throw new Error('Method not implemented.');
  }

 /**
   * Get Session user(Pegar sessão do  usuario)
   * @returns get user session
   */
  public getUser(){
    let store = sessionStorage["session"] ? JSON.parse(sessionStorage["session"]) : [];
    let id = null;
    if( store[0]["id_user"] > 0  && store != null){
     id  = store[0]["id_user"];
    }
    return id;
   }


    /**
   * Get Session dominio user(Pegar sessão do  usuario)
   * @returns get user session
   */
  public getUserDomain(){
    let dm = null;
    try{
      let store = sessionStorage["session"] ? JSON.parse(sessionStorage["session"]) : [];
    
      if( store[0]["domain"]){
      dm  = store[0]["domain"];
      }
    }catch(erro){
 
       console.error("Erro para setar dominio");
        
    }
      return dm;
   }


  
  /**Get Dominio Veiculo */
  public getDomainVehicle(domain:string): Observable<Vehicle>{
    return this.http.get<Vehicle>(this.url1 + '/vehicle/domain/'+`${domain}`);
  }
  
       
  /**Get Dominio Veiculo */
  public getDomainVehicle_Profile(domain:string): Observable<Vehicle_User>{
    return this.http.get<Vehicle_User>(this.url1 + '/vehicle/domain/profile/'+`${domain}`);
  }
  
    
  /**Get Dominio Veiculo redux*/
  public getDomainVehicle_redux(domain:string): Observable<Vehicle_Redux>{
    return this.http.get<Vehicle_Redux>(this.url1 + '/vehicle/domain/redux/'+`${domain}`);
  }

       
  /*send email- usuario para veiculo*/
  public sendEmail_VH(domain:string,send:SendEmail): Observable<any>{
    return this.http.get<any>(this.url1 + '/email/send/'+`${domain}`+"?email="+`${send.email}`+"&subject="+`${send.subject}`+"&content="+`${send.content}`);
  }
  
  /**Get todos os Veiculo */
  public getAllVehicle(size:number,page:number): Observable<VehicleCT>{
    return this.http.get<VehicleCT>(this.url1 + '/vehicle/datavehicle/all?'+"size="+`${size}`+"&page="+`${page}`);
  }
  
 
  /**Get contact*/
  public ContactVehicle(domain:string): Observable<ContactVH>{
    return this.http.get<ContactVH>(this.url1 + '/vehicle/contact/'+`${domain}`);
  }
  

/////////////////////DATA-PROVEDOR////////////////////////////////////////////////////////
  public anddressProvedor(): Observable<AddressPV>{
    return this.http.get<AddressPV>(this.url1 + '/uservehicle/data/address?cn='+`${this.authUser.cnpj}`);
  }

  public anddressProvedorDomain(domain:string): Observable<AddressPV>{
    return this.http.get<AddressPV>(this.url1 + '/uservehicle/data/address/'+domain);
  }
  
  public getAuthConfig(): Observable<AuthConfig>{
    return this.http.get<AuthConfig>(this.url + '/auth/auth-config.json');
  }
 ///////////////////////////LOGIN-AUTH///////////////////////////////////
/**
   * Realiza a chamada para a API Login(caminho base).
   *
   * @return Observable<ConversaoResponse> 
   */
 public login(login:Login):Observable<any>{
  return this.http.post(this.url1+"/admin/user/auth",login);
 }
 
/**
   * Criar um usuário (Registro).
   *
   * @return Observable<UserData> 
   */
 public createUser(user:UserData):Observable<UserData>{
  return this.http.post<UserData>(this.url1+"/admin/user",user);
 }
 

/**Select usuário
   * Find user 
   * @return Observable<reponseUser> 
   */
 public findUser(user:string):Observable<any>{
  return this.http.get(this.url1+"/admin/user/find/"+user);
}

/////////////////////////////////FAQS/////////////////////////////////
public getFaqs(): Observable<Faqs[]>{
  return this.http.get<Faqs[]>(this.url1+"/faqs");
} 

////////////////////////////////Videos-News/////////////////////////////////////
public getNewsVideos(size:number): Observable<videoMedia[]>{
  return this.http.get<videoMedia[]>(this.url1+"/vid?size="+`${size}`);
}  
////////////////////////////////////////////////////////////////////////////////////////////////////////
 public getNewsAll(namevh:string,day:number,genres:string[],pages:PageSize): Observable<NewsCPDTO>{
    return this.http.get<NewsCPDTO>(this.url1+"/newsComplete?"+`vh=${namevh}&size=${Math.round(pages.size)}&ge=${genres}&day=${day}`);
  }  
  public getNewsLine(namevh:string,day:number,genres:string[],pages:PageSize): Observable<NewsLine[]>{
    return this.http.get<NewsLine[]>(this.url1+"/newsComplete/newsline?"+`vh=${namevh}&size=${Math.round(pages.size)}&gere=${genres}&day=${day}`);
  }  
///////////////////////////////////////////////COLUNISTAS//////////////////////////////////////////////////////
public getColumns(): Observable<Columns[]>{
  return this.http.get<Columns[]>(this.url1+"/columns");
} 
///////////////////////////////////////////////COLUNISTAS//////////////////////////////////////////////////////
public getColumnsDomain(domain:string): Observable<Columns[]>{
  return this.http.get<Columns[]>(this.url1+"/columns/dm/"+domain);
} 
    /**
   * GET ID News
   * @param params 
   * @param pag 
   * @returns  observeble
   */

    public findIdNews(id:number):Observable<NewsCPDTO>{
      return this.http.get<NewsCPDTO>(this.url1+"/newsComplete/"+id);
    }
    

public getColumnsByName(name:string): Observable<Columns>{
  return this.http.get<Columns>(this.url1+"/columns/name/"+name);
} 

public getColumnsById(id:number): Observable<Columns>{
  return this.http.get<Columns>(this.url1+"/columns/"+id);
} 
//////////////////////////////////COMENTARIOS DA COLUNAS//////////////////////////////////////////////////////////////////////////////
public getCommentColumnsBySubject(subject:string): Observable<ColumnsComment>{
  return this.http.get<ColumnsComment>(this.url1+"/comment/column/comment?"+`subject=${this.encodeValue(subject)}`);
} 

public getCommentColumns(): Observable<ColumnsComment>{
  return this.http.get<ColumnsComment>(this.url1+"/comment/column");
}
/////////////////////////////////ANUNCIOS-Adverts////////////////////////////////////////
public getAdverts(): Observable<Adverts[]>{
  return this.http.get<Adverts[]>(this.url1+"/Adverts");
}
public getCommentAdvertsTitle(title:string): Observable<Adverts>{
  return this.http.get<Adverts>(this.url1+"/Adverts/title?"+`text=${this.encodeValue(title)}`);
}

public createComment(comment:CommentCreateUser,nortify:boolean): Observable<any>{
  return this.http.post(this.url1+"/comments?"+`nortify=${nortify}`,comment);
}

public createCommentVideo(comment:CommentCreateUser,nortify:boolean): Observable<any>{
  return this.http.post(this.url1+"/vid?"+`nortify=${nortify}`,comment);
}





/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

 /**
  * Manchete
   * obs por nome do veiculo
   * @param name 
   * @param filter 
   * @param page 
   * @returns 
   */
  public getNewsManchete(namevh:string,day:number,genre:any,page:PageSize): Observable<NewsCPDTO>{
    return this.http.get<NewsCPDTO>(this.url1+"/newsComplete"+`?size=${Math.round(page.size)}&ge=${genre}&day=${day}&vh=${namevh}`);
  }  

 /**
  * News IMG
   * obs por nome do veiculo
   * @param name 
   * @param filter 
   * @param page 
   * @returns 
   */
  public getNewsIMG(namevh:string,day:number,genre:any,page:PageSize): Observable<NewsIMG>{
    return this.http.get<NewsIMG>(this.url1+"/newsComplete/news/img"+`?size=${Math.round(page.size)}&ge=${genre}&day=${day}&vh=${namevh}`);
  }  
/**
   * Pagination
   * @param filter 
   * @returns 
   */
  public getNewsFiltersFont(filter:Filters): Observable<NewsCPDTO>{
    return this.http.get<NewsCPDTO>(this.url1+"/newsComplete/filtfont/font/"+filter.font+"/day/"+filter.days+"?size=6&page=0");
  }  


 /**
  * Auto Sugesão para campo de pesquisa
   * obs por nome do veiculo
   * @param name 
   * @param day 
   * @param page 
   * @returns 
   */
  public  autoSugentao(namevh:string,genre:any,day:number,page:PageSize): Observable<Array<string>>{
    return this.http.get<Array<string>>(this.url1+"/newsComplete/sg/search"+`?size=${Math.round(page.size)}&gere=${genre}&day=${day}&vh=${namevh}`);
  }  

 /**
  * Auto Sugesão para campo de pesquisa (por data)
   * obs por nome do veiculo
   * @param name 
   * @param DATA (dd/mm/yyyy)
   * @param page 
   * @returns 
   */
  public  autoSugentaoData(namevh:string,genre:any,dataIn:string,dataOut:string,page:PageSize): Observable<NewsCPDTO>{
    return this.http.get<NewsCPDTO>(this.url1+"/newsComplete/search/date"+`?size=${Math.round(page.size)}&gere=${genre}&dtin=${dataIn}&dtout=${dataOut}&vh=${namevh}`);
  }  

  /**
   * obs por nome do veiculo
   * @param name 
   * @param filter 
   * @param page 
   * @returns 
   */
  public getNewsFiltersFontById(name:string,filter:Filters,page:PageSize): Observable<NewsCPDTO>{
    return this.http.get<NewsCPDTO>(this.url1+"/newsComplete/filtvehicle/vh/"+name+"/day/"+filter.days+`?size=${Math.round(page.size)}&page${page.pag}`);
  }  

  /**
   * obs por nome do veiculo e genero da fontes
   * @param name 
   * @param filter 
   * @param page 
   * @returns 
   */
  public getNewsFiltersFontGenre(genre:string,name:string,filter:Filters,page:PageSize): Observable<NewsCPDTO>{
    return this.http.get<NewsCPDTO>(this.url1+"/newsComplete/filt/genre/"+genre+"/vh/"+name+"/day/"+filter.days+`?size=${Math.round(page.size)}&page=${page.pag}`);
  }  

  
  /**
   * obs REDUX por nome do veiculo e genero da fontes
   * @param name 
   * @param filter 
   * @param page 
   * @returns 
   */
  public getNewsFiltersFontGenre_redux(genre:string,name:string,filter:Filters,page:PageSize): Observable<NewsCPDTO>{
    return this.http.get<NewsCPDTO>(this.url1+"/newsComplete/filt/redux/genre/"+genre+"/vh/"+name+"/day/"+filter.days+`?size=${Math.round(page.size)}&page=${page.pag}`);
  }  

  
  
  /**
   * Resposta I.A
   * @param  ResponseIA
   * @returns 
   */
  public postresponseIA(object:ResponseIA):Observable<DataIa>{

    return this.http.post<DataIa>(this.url1+"/axl/ia/",object);
    
  }



 /**
  *  REDUZ UNICO
   * obs por nome do veiculo
   * @param name 
   * @param filter 
   * @param page 
   * @returns 
   */
 public getNewsManchete_redux(namevh:string,day:number,genre:any,page:PageSize): Observable<NewsCPDTO>{
  return this.http.get<NewsCPDTO>(this.url1+"/newsComplete/redux"+`?size=${Math.round(page.size)}&ge=${genre}&day=${day}&vh=${namevh}`);
}  


 /**
  *  REDUZ Internacional
   * obs por nome do veiculo
   * @param name 
   * @param filter 
   * @param page 
   * @returns 
   */
 public getNewsManchete_redux_internacional(namevh:string,day:number,genre:any,page:PageSize): Observable<NewsCPDTO>{
  return this.http.get<NewsCPDTO>(this.url1+"/newsComplete/internacional/all"+`?size=${page.size}&ge=${genre}&day=${day}&vh=${namevh}`);
}  

 /**
  *  REDUZ UNICO NEWSLINE
   * obs por nome do veiculo
   * @param name 
   * @param filter 
   * @param page 
   * @returns 
   */
 public getNewsManchete_redux_newsline(namevh:string,day:number,genre:any,page:PageSize,qty:number): Observable<NewsCPDTO>{
  return this.http.get<NewsCPDTO>(this.url1+"/newsComplete/redux/newsline"+`?size=${page.size}&ge=${genre}&day=${day}&vh=${namevh}&qty=${qty}`);
}  

 /**
  *  REDUX MULT
   * obs por nome do veiculo
   * @param name 
   * @param filter 
   * @param page 
   * @returns 
   */
 public getNewsManchete_redux_mult(namevh:string,day:number,genre:any,page:PageSize): Observable<NewsCPDTO>{
  return this.http.get<NewsCPDTO>(this.url1+"/newsComplete/redux/mult"+`?size=${page.size}&ge=${genre}&day=${day}&vh=${namevh}`);
}  


  public getNewsById(id:number): Observable<NewsCPDTO>{
    return this.http.get<NewsCPDTO>(this.url1 +"/newsComplete/"+ id);
  } 

  public getNewsBySource(source:string): Observable<NewsCPDTO>{
    return this.http.get<NewsCPDTO>(this.url1 +"/newsComplete/FontAll?"+`font=${source}`);
  }
  public getNewsByTitle(title:string): Observable<NewsCPCOMPLET>{
    return this.http.get<NewsCPCOMPLET>(this.url1 +"/newsComplete/findcp?"+`text=${this.encodeValue(title)}`);
  }    
  public getNewsByTitle_AI(title:string): Observable<NewsCPCOMPLET>{
    return this.http.get<NewsCPCOMPLET>(this.url1 +"/newsComplete/findcpai?"+`text=${this.encodeValue(title)}`);
  }  
  ////Publicidade//
  public getPublicity(): Observable<Publicity[]>{
    return this.http.get<Publicity[]>(this.url1 +"/publicity/cnp?cnpj="+`${this.authUser.cnpj}`);
  } 
  ////Publicidade//
  public getPublicityDomain(domain:string): Observable<Publicity[]>{
    return this.http.get<Publicity[]>(this.url1 +"/publicity/dm/"+`${domain}`);
  } 

  public getVehicleAbout(): Observable<About>{
    return this.http.get<About>(this.url1 +"/about/cnpj?cnpj="+`${this.authUser.cnpj}`);
  }   
  public getVehicleAboutDomain(doman:string): Observable<About>{
    return this.http.get<About>(this.url1 +"/about/dm?dm="+`${doman}`);
  } 
  ///////////////////////////////////////USUARIO///////////////////////////
  public getByUser(id:number): Observable<UserData>{
    return this.http.get<UserData>(this.url1 +"/admin/user/"+id);
  } 
  public getByUserName(name:string): Observable<UserData>{
    return this.http.get<UserData>(this.url1 +"/admin/user/"+name);
  } 
  public uploadFileUser(file,id:number): Observable<any>{
    const uploadData = new FormData();
    uploadData.append('foto', file,file.name);
    return this.http.post(this.url1+"/admin/user/file/"+id,uploadData  ,{
      reportProgress: true,
      observe: 'events'
     });
  } 
  /////////////////////////////////COLUNAS/////////////////////////////////////////
  public getColuns(): Observable<Coluns[]>{
    return this.http.get<Coluns[]>(this.url +"colunas.json");
  } 
  ////////////////////////////////SOCIAL-MIDIA/////////////////////////////////////
  public getSocialMidia(): Observable<Insta[]>{
    return this.http.get<Insta[]>(this.url +"socialmidia.json");
  } 
 ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////SHOPING-CART////////////////////////////////////////////////////////
  public getShopping(domain:string): Observable<Shopping[]>{
    return this.http.get<Shopping[]>(this.url1 +"/shoppingcart/domain/"+domain);
  }  
  public getShoppingUser(login:number): Observable<Shopping[]>{
    return this.http.get<Shopping[]>(this.url1 +"/shoppingcart/login/"+login);
  } 

  public getProperties(): Observable<Property[]>{
    return this.http.get<Property[]>(this.url + 'properties.json');
  }

  public getPropertyById(id): Observable<Property>{
    return this.http.get<Property>(this.url + 'property-' + id + '.json');
  }

  public getFeaturedProperties(): Observable<Property[]>{
    return this.http.get<Property[]>(this.url + 'featured-properties.json');
  } 

  public getRelatedProperties(): Observable<Property[]>{
    return this.http.get<Property[]>(this.url + 'related-properties.json');
  }

  public getPropertiesByAgentId(agentId): Observable<Property[]>{
    return this.http.get<Property[]>(this.url + 'properties-agentid-' + agentId + '.json');
  }

  public getLocations(): Observable<Location[]>{
    return this.http.get<Location[]>(this.url + 'locations.json');
  }

  public getAddress(lat = 40.714224, lng = -73.961452){ 
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng='+lat+','+lng+'&key='+this.apiKey);
  }

  public getLatLng(address){ 
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?key='+this.apiKey+'&address='+address);
  }

  public getFullAddress(lat = 40.714224, lng = -73.961452){ 
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng='+lat+','+lng+'&key='+this.apiKey).subscribe(data =>{ 
      return data['results'][0]['formatted_address'];
    });
  }

  public getErro(property:Property, direction){
      this.snackBar.open('The property "' + "Erro" + '" has been added to favorites.', '×', {
        verticalPosition: 'top',
        duration: 3000,
        direction: direction 
      });   
  }

  
  public getSuccess(property:Property,sucess:string, direction){
      this.snackBar.open('The property "' + sucess + '" has been added to favorites.', '×', {
        verticalPosition: 'top',
        duration: 3000,
        direction: direction 
      });    
  }


  public addToCompare(property:Property, component, direction){ 
    if(!this.Data.compareList.filter(item=>item.id == property.id)[0]){
      this.Data.compareList.push(property);
      this.bottomSheet.open(component, {
        direction: direction
      }).afterDismissed().subscribe(isRedirect=>{  
        if(isRedirect){
          if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0,0);
          }
        }        
      }); 
    } 
  }

  public addToFavorites(property:NewsCP, direction){
    if(!this.Data.favorites.filter(item => item.id == property.id)[0]){
      this.Data.favorites.push(property);
      this.snackBar.open('The property "' + property.title + '" has been added to favorites.', '×', {
        verticalPosition: 'top',
        duration: 3000,
        direction: direction 
      });  
    }    
  }

  public openConfirmDialog(title:string, message:string) {  
    const dialogData = new ConfirmDialogModel(title, message); 
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    }); 
    return dialogRef; 
  }

  public openAlertDialog(message:string) {   
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      maxWidth: "400px",
      data: message
    }); 
    return dialogRef; 
  }

  public getTranslateValue(key:string, param:string = null){  
    let value = null;
    this.translateService.get(key, { param: param }).subscribe((res: string) => {
      value = res;
    }) 
    return value; 
  }

  public getPropertyTypes(){
    return [ 
      { id: 1, name: 'Masculino' },
      { id: 2, name: 'Femenino' },
      { id: 3, name: 'Outros' }
    ]
  }

  public getPropertyStatuses(){
    return [ 
      { id: 1, name: 'online' },
      { id: 2, name: 'Impresso' },
      { id: 3, name: 'Nenhum' }
    ]
  }

  public getCities(){
    return [ 
      { id: 1, name: 'Masculino' },
      { id: 2, name: 'Femenino' },
      { id: 3, name: 'Outros' }
    ]
  }

  public getNeighborhoods(){
    return [      
      { id: 1, name: 'Astoria', cityId: 1 },
      { id: 2, name: 'Midtown', cityId: 1 },
      { id: 3, name: 'Chinatown', cityId: 1 }, 
      { id: 4, name: 'Austin', cityId: 2 },
      { id: 5, name: 'Englewood', cityId: 2 },
      { id: 6, name: 'Riverdale', cityId: 2 },      
      { id: 7, name: 'Hollywood', cityId: 3 },
      { id: 8, name: 'Sherman Oaks', cityId: 3 },
      { id: 9, name: 'Highland Park', cityId: 3 },
      { id: 10, name: 'Belltown', cityId: 4 },
      { id: 11, name: 'Queen Anne', cityId: 4 },
      { id: 12, name: 'Green Lake', cityId: 4 }      
    ]
  }

  public getStreets(){
    return [      
      { id: 1, name: 'Astoria Street #1', cityId: 1, neighborhoodId: 1},
      { id: 2, name: 'Astoria Street #2', cityId: 1, neighborhoodId: 1},
      { id: 3, name: 'Midtown Street #1', cityId: 1, neighborhoodId: 2 },
      { id: 4, name: 'Midtown Street #2', cityId: 1, neighborhoodId: 2 },
      { id: 5, name: 'Chinatown Street #1', cityId: 1, neighborhoodId: 3 }, 
      { id: 6, name: 'Chinatown Street #2', cityId: 1, neighborhoodId: 3 },
      { id: 7, name: 'Austin Street #1', cityId: 2, neighborhoodId: 4 },
      { id: 8, name: 'Austin Street #2', cityId: 2, neighborhoodId: 4 },
      { id: 9, name: 'Englewood Street #1', cityId: 2, neighborhoodId: 5 },
      { id: 10, name: 'Englewood Street #2', cityId: 2, neighborhoodId: 5 },
      { id: 11, name: 'Riverdale Street #1', cityId: 2, neighborhoodId: 6 }, 
      { id: 12, name: 'Riverdale Street #2', cityId: 2, neighborhoodId: 6 },
      { id: 13, name: 'Hollywood Street #1', cityId: 3, neighborhoodId: 7 },
      { id: 14, name: 'Hollywood Street #2', cityId: 3, neighborhoodId: 7 },
      { id: 15, name: 'Sherman Oaks Street #1', cityId: 3, neighborhoodId: 8 },
      { id: 16, name: 'Sherman Oaks Street #2', cityId: 3, neighborhoodId: 8 },
      { id: 17, name: 'Highland Park Street #1', cityId: 3, neighborhoodId: 9 },
      { id: 18, name: 'Highland Park Street #2', cityId: 3, neighborhoodId: 9 },
      { id: 19, name: 'Belltown Street #1', cityId: 4, neighborhoodId: 10 },
      { id: 20, name: 'Belltown Street #2', cityId: 4, neighborhoodId: 10 },
      { id: 21, name: 'Queen Anne Street #1', cityId: 4, neighborhoodId: 11 },
      { id: 22, name: 'Queen Anne Street #2', cityId: 4, neighborhoodId: 11 },
      { id: 23, name: 'Green Lake Street #1', cityId: 4, neighborhoodId: 12 },
      { id: 24, name: 'Green Lake Street #2', cityId: 4, neighborhoodId: 12 }      
    ]
  }

  public getFeatures(){
    return [ 
      { id: 1, name: 'Air Conditioning', selected: false },
      { id: 2, name: 'Barbeque', selected: false },
      { id: 3, name: 'Dryer', selected: false },
      { id: 4, name: 'Microwave', selected: false }, 
      { id: 5, name: 'Refrigerator', selected: false },
      { id: 6, name: 'TV Cable', selected: false },
      { id: 7, name: 'Sauna', selected: false },
      { id: 8, name: 'WiFi', selected: false },
      { id: 9, name: 'Fireplace', selected: false },
      { id: 10, name: 'Swimming Pool', selected: false },
      { id: 11, name: 'Gym', selected: false },
    ]
  }


  public getHomeCarouselSlides(){
    return this.http.get<any[]>(this.url + 'slides.json');
  }


  public filterData(data, params: any, sort?, page?, perPage?){ 
   
    if(params){

      if(params.propertyType){
        data = data.filter(property => property.propertyType == params.propertyType.name)
      }

      if(params.propertyStatus && params.propertyStatus.length){       
        let statuses = [];
        params.propertyStatus.forEach(status => { statuses.push(status.name) });           
        let properties = [];
        data.filter(property =>
          property.propertyStatus.forEach(status => {             
            if(statuses.indexOf(status) > -1){                 
              if(!properties.includes(property)){
                properties.push(property);
              }                
            }
          })
        );
        data = properties;
      }

      if(params.price){
        if(this.appSettings.settings.currency == 'USD'){          
          if(params.price.from){
            data = data.filter(property => {
              if(property.priceDollar.sale && property.priceDollar.sale >= params.price.from ){
                return true;
              }
              if(property.priceDollar.rent && property.priceDollar.rent >= params.price.from ){
                return true;
              } 
              return false;
            });
          }
          if(params.price.to){
            data = data.filter(property => {
              if(property.priceDollar.sale && property.priceDollar.sale <= params.price.to){
                return true;
              }
              if(property.priceDollar.rent && property.priceDollar.rent <= params.price.to){
                return true;
              } 
              return false;
            });          
          }
        }
        if(this.appSettings.settings.currency == 'EUR'){
          if(params.price.from){
            data = data.filter(property => {
              if(property.priceEuro.sale && property.priceEuro.sale >= params.price.from ){
                return true;
              }
              if(property.priceEuro.rent && property.priceEuro.rent >= params.price.from ){
                return true;
              } 
              return false;
            });

          }
          if(params.price.to){
            data = data.filter(property => {
              if(property.priceEuro.sale && property.priceEuro.sale <= params.price.to){
                return true;
              }
              if(property.priceEuro.rent && property.priceEuro.rent <= params.price.to){
                return true;
              } 
              return false;
            });
          }
        }        
      }  

      if(params.city){
        data = data.filter(property => property.city == params.city.name)
      }

      if(params.zipCode){
        data = data.filter(property => property.zipCode == params.zipCode)
      }
      
      if(params.neighborhood && params.neighborhood.length){       
        let neighborhoods = [];
        params.neighborhood.forEach(item => { neighborhoods.push(item.name) });           
        let properties = [];
        data.filter(property =>
          property.neighborhood.forEach(item => {             
            if(neighborhoods.indexOf(item) > -1){                 
              if(!properties.includes(property)){
                properties.push(property);
              }                
            }
          })
        );
        data = properties;
      }

      if(params.street && params.street.length){       
        let streets = [];
        params.street.forEach(item => { streets.push(item.name) });           
        let properties = [];
        data.filter(property =>
          property.street.forEach(item => {             
            if(streets.indexOf(item) > -1){                 
              if(!properties.includes(property)){
                properties.push(property);
              }                
            }
          })
        );
        data = properties;
      }

      if(params.bedrooms){
        if(params.bedrooms.from){
          data = data.filter(property => property.bedrooms >= params.bedrooms.from)
        }
        if(params.bedrooms.to){
          data = data.filter(property => property.bedrooms <= params.bedrooms.to)
        }
      } 
      
      if(params.bathrooms){
        if(params.bathrooms.from){
          data = data.filter(property => property.bathrooms >= params.bathrooms.from)
        }
        if(params.bathrooms.to){
          data = data.filter(property => property.bathrooms <= params.bathrooms.to)
        }
      } 

      if(params.garages){
        if(params.garages.from){
          data = data.filter(property => property.garages >= params.garages.from)
        }
        if(params.garages.to){
          data = data.filter(property => property.garages <= params.garages.to)
        }
      } 

      if(params.area){
        if(params.area.from){
          data = data.filter(property => property.area.value >= params.area.from)
        }
        if(params.area.to){
          data = data.filter(property => property.area.value <= params.area.to)
        }
      } 

      if(params.yearBuilt){
        if(params.yearBuilt.from){
          data = data.filter(property => property.yearBuilt >= params.yearBuilt.from)
        }
        if(params.yearBuilt.to){
          data = data.filter(property => property.yearBuilt <= params.yearBuilt.to)
        }
      }

      if(params.features){       
        let arr = [];
        params.features.forEach(feature => { 
          if(feature.selected)
            arr.push(feature.name);
        });  
        if(arr.length > 0){
          let properties = [];
          data.filter(property =>
            property.features.forEach(feature => {             
              if(arr.indexOf(feature) > -1){                 
                if(!properties.includes(property)){
                  properties.push(property);
                }                
              }
            })
          );
          data = properties;
        }         
        
      }
      
    }

    // console.log(data)

    //for show more properties mock data 
    for (var index = 0; index < 2; index++) {
      data = data.concat(data);        
    }     
     
    this.sortData(sort, data);
    return this.paginator(data, page, perPage)
  }

  public sortData(sort, data){
    if(sort){
      switch (sort) {
        case 'Newest':
          data = data.sort((a, b)=> {return <any>new Date(b.published) - <any>new Date(a.published)});           
          break;
        case 'Oldest':
          data = data.sort((a, b)=> {return <any>new Date(a.published) - <any>new Date(b.published)});           
          break;
        case 'Popular':
          data = data.sort((a, b) => { 
            if(a.ratingsValue/a.ratingsCount < b.ratingsValue/b.ratingsCount){
              return 1;
            }
            if(a.ratingsValue/a.ratingsCount > b.ratingsValue/b.ratingsCount){
              return -1;
            }
            return 0; 
          });
          break;
        case 'Price (Low to High)':
          if(this.appSettings.settings.currency == 'USD'){
            data = data.sort((a,b) => {
              if((a.priceDollar.sale || a.priceDollar.rent) > (b.priceDollar.sale || b.priceDollar.rent)){
                return 1;
              }
              if((a.priceDollar.sale || a.priceDollar.rent) < (b.priceDollar.sale || b.priceDollar.rent)){
                return -1;
              }
              return 0;  
            }) 
          }
          if(this.appSettings.settings.currency == 'EUR'){
            data = data.sort((a,b) => {
              if((a.priceEuro.sale || a.priceEuro.rent) > (b.priceEuro.sale || b.v.rent)){
                return 1;
              }
              if((a.priceEuro.sale || a.priceEuro.rent) < (b.priceEuro.sale || b.priceEuro.rent)){
                return -1;
              }
              return 0;  
            }) 
          }
          break;
        case 'Price (High to Low)':
          if(this.appSettings.settings.currency == 'USD'){
            data = data.sort((a,b) => {
              if((a.priceDollar.sale || a.priceDollar.rent) < (b.priceDollar.sale || b.priceDollar.rent)){
                return 1;
              }
              if((a.priceDollar.sale || a.priceDollar.rent) > (b.priceDollar.sale || b.priceDollar.rent)){
                return -1;
              }
              return 0;  
            }) 
          }
          if(this.appSettings.settings.currency == 'EUR'){
            data = data.sort((a,b) => {
              if((a.priceEuro.sale || a.priceEuro.rent) < (b.priceEuro.sale || b.v.rent)){
                return 1;
              }
              if((a.priceEuro.sale || a.priceEuro.rent) > (b.priceEuro.sale || b.priceEuro.rent)){
                return -1;
              }
              return 0;  
            }) 
          }
          break;
        default:
          break;
      }
    }
    return data;
  }

  public paginator(items, page?, perPage?) { 
    var page = page || 1,
    perPage = perPage || 4,
    offset = (page - 1) * perPage,   
    paginatedItems = items.slice(offset).slice(0, perPage),
    totalPages = Math.ceil(items.length / perPage);
    return {
      data: paginatedItems,
      pagination:{
        page: page,
        perPage: perPage,
        prePage: page - 1 ? page - 1 : null,
        nextPage: (totalPages > page) ? page + 1 : null,
        total: items.length,
        totalPages: totalPages,
      }
    };
  }



  public getTestimonials(){
    return [
        { 
            text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.', 
            author: 'Mr. Adam Sandler', 
            position: 'General Director', 
            image: 'assets/images/profile/adam.jpg' 
        },
        { 
            text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.', 
            author: 'Ashley Ahlberg', 
            position: 'Housewife', 
            image: 'assets/images/profile/ashley.jpg' 
        },
        { 
            text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.', 
            author: 'Bruno Vespa', 
            position: 'Blogger', 
            image: 'assets/images/profile/bruno.jpg' 
        },
        { 
            text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.', 
            author: 'Mrs. Julia Aniston', 
            position: 'Marketing Manager', 
            image: 'assets/images/profile/julia.jpg' 
        }
    ];
  }

  public getAgents(){
    return [        
        { 
            id: 1,
            fullName: 'Lusia Manuel',
            desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
            organization: 'HouseKey',
            email: 'lusia.m@housekey.com',
            phone: '(224) 267-1346',
            social: {
              facebook: 'lusia',
              twitter: 'lusia',
              linkedin: 'lusia',
              instagram: 'lusia',
              website: 'https://lusia.manuel.com'
            },
            ratingsCount: 6,
            ratingsValue: 480,
            image: 'assets/images/agents/a-1.jpg' 
        },
        { 
            id: 2,
            fullName: 'Andy Warhol',
            desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
            organization: 'HouseKey',
            email: 'andy.w@housekey.com',
            phone: '(212) 457-2308',
            social: {
              facebook: '',
              twitter: '',
              linkedin: '',
              instagram: '',
              website: 'https://andy.warhol.com'
            },
            ratingsCount: 4,
            ratingsValue: 400,
            image: 'assets/images/agents/a-2.jpg' 
        },        
        { 
            id: 3,
            fullName: 'Tereza Stiles',
            desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
            organization: 'HouseKey',
            email: 'tereza.s@housekey.com',
            phone: '(214) 617-2614',
            social: {
              facebook: '',
              twitter: '',
              linkedin: '',
              instagram: '',
              website: 'https://tereza.stiles.com'
            },
            ratingsCount: 4,
            ratingsValue: 380,
            image: 'assets/images/agents/a-3.jpg' 
        },
        { 
          id: 4,
          fullName: 'Michael Blair',
          desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
          organization: 'HouseKey',
          email: 'michael.b@housekey.com',
          phone: '(267) 388-1637',
          social: {
            facebook: '',
            twitter: '',
            linkedin: '',
            instagram: '',
            website: 'https://michael.blair.com'
          },
          ratingsCount: 6,
          ratingsValue: 480,
          image: 'assets/images/agents/a-4.jpg'  
        },
        { 
            id: 5,
            fullName: 'Michelle Ormond',
            desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
            organization: 'HouseKey',
            email: 'michelle.o@housekey.com',
            phone: '(267) 388-1637',
            social: {
              facebook: '',
              twitter: '',
              linkedin: '',
              instagram: '',
              website: 'https://michelle.ormond.com'
            },
            ratingsCount: 6,
            ratingsValue: 480, 
            image: 'assets/images/agents/a-5.jpg' 
        }
    ];
  }


  /**
   * GET Error Response
   * @param error_ 
   * @returns 
   */
  public getResponseError(error_:any){
      let error:string="";
      switch(error_.status){
        case 400:
          error = error_.error.error[0];
        break;

        case 401 :  case 403 :
            error = "Acesso não autorizado";
          break;

        case 404:
            error = "Essa  pagina não existe";
          break;

        case 500:
            error = "Erro no servidor";
          break;

        default:
            error = "Alguma coisa deu errado, tente novamente mais tarde !";
            break

    }

    return error;
  }


  public getClients(){
    return [  
        { name: 'aloha', image: 'assets/images/clients/aloha.png' },
        { name: 'dream', image: 'assets/images/clients/dream.png' },  
        { name: 'congrats', image: 'assets/images/clients/congrats.png' },
        { name: 'best', image: 'assets/images/clients/best.png' },
        { name: 'original', image: 'assets/images/clients/original.png' },
        { name: 'retro', image: 'assets/images/clients/retro.png' },
        { name: 'king', image: 'assets/images/clients/king.png' },
        { name: 'love', image: 'assets/images/clients/love.png' },
        { name: 'the', image: 'assets/images/clients/the.png' },
        { name: 'easter', image: 'assets/images/clients/easter.png' },
        { name: 'with', image: 'assets/images/clients/with.png' },
        { name: 'special', image: 'assets/images/clients/special.png' },
        { name: 'bravo', image: 'assets/images/clients/bravo.png' }
    ];
  }



/**Follter domain url */
/**
 * 
 * @param {string} url 
 */
public getUrl(url) {
  let domain = "";

  // Verificar se há '?' na URL
  let questionMarkIndex = url.indexOf('?');
  if (questionMarkIndex !== -1) {
      // Se houver um '?', pegar a substring antes dele
      url = url.substring(0, questionMarkIndex);
  }

  // Remover "http://" ou "https://"
  url = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");

  // Verificar se há barras na URL
  let lastSlashIndex = url.lastIndexOf('/');
  if (lastSlashIndex !== -1) {
      // Se houver barras, pegar a substring depois da última barra
      domain = url.substring(lastSlashIndex + 1);
  } else {
      // Se não houver barras, o domínio é toda a URL
      domain = url;
  }

  // Remover parâmetros de consulta, se houver
  let queryIndex = domain.indexOf('?');
  if (queryIndex !== -1) {
      domain = domain.substring(0, queryIndex);
  }

  return domain;
}


private formatPhoneNumber(phoneNumber: string): string {
  // Remove todos os caracteres não numéricos
  const cleaned = phoneNumber.replace(/\D/g, '');
  
  // Verifica se o número possui o formato correto
  const regex = /^(\d{2})(\d{4,5})(\d{4})$/;
  if (!regex.test(cleaned)) {
      throw new Error('Número de telefone inválido');
  }
  
  // Formata o número conforme o padrão (XX) XXXXX-XXXX
  const formatted = cleaned.replace(regex, '($1) $2-$3');
  
  return formatted;
}








}
