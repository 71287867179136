import { Component, Input, OnInit } from '@angular/core';


export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent implements OnInit {

  /**
   * Ativar skeleton para bloco correspondente
   * M (Manchete)
   * C (Bloco ) 4 X 3
   * B1 bloco 2X3
   */

  @Input() name:string  = "M";
  @Input() grids: number;


  public tiles: Tile[] = [
    {text: 'One', cols: 2, rows:2, color: 'lightblue'},
    {text: 'Two', cols: 1, rows: 2, color: 'lightgreen'},
    {text: 'Four', cols: 1, rows: 2, color: '#DDBDF1'},
    {text: 'Three', cols: 1, rows: 2, color: 'lightpink'},
    {text: '9', cols: 1, rows: 2, color: 'lightpink'},
    {text: '6', cols: 1, rows: 2, color: 'lightpink'},
    {text: 'Three', cols: 1, rows: 2, color: 'lightpink'},
    {text: '5', cols: 1, rows: 2, color: 'lightpink'},
    {text: '6', cols: 1, rows: 2, color: 'lightpink'},
    {text: '7', cols: 1, rows: 2, color: 'lightpink'},
    {text: '8', cols: 1, rows: 2, color: 'lightpink'},
  ];

    /**config skeleton */
    public skel = 
    { 
      'border-radius': '5px',
       width:'300px',
       height: '300px',
       'background-color': 'rgb(215 215 215)', 
       border: '1px solid white'
   };

  constructor() { }

  ngOnInit(): void {
  }

}
