import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';



@Pipe({
    name: 'dateTimeFormat'
  })
  export class DateTimeFormatPipe extends DatePipe implements PipeTransform {
  
    transform(date:string): any {
     let dateOut:Moment = moment(date,"YYYY-MM-DDTHH:mm:ss");
     return dateOut.format("DD MMM Y HH:mm");
    }
  
  }

  /*
   * moment.locale();         // en
    moment().format('LT');   // 8:41 PM
    moment().format('LTS');  // 8:41:43 PM
    moment().format('L');    // 04/11/2020
    moment().format('l');    // 4/11/2020
    moment().format('LL');   // April 11, 2020
    moment().format('ll');   // Apr 11, 2020
    moment().format('LLL');  // April 11, 2020 8:41 PM
    moment().format('lll');  // Apr 11, 2020 8:41 PM
    moment().format('LLLL'); // Saturday, April 11, 2020 8:41 PM
    moment().format('llll'); // Sat, Apr 11, 2020 8:41 PM
    moment().format('MMMM Do YYYY, h:mm:ss a'); // March 5th 2021, 11:30:49 am
      moment().format('dddd');                    // Friday
      moment().format("MMM Do YY");               // Mar 5th 21
      moment().format('YYYY [escaped] YYYY');     // 2021 escaped 2021
      moment().format('YYYY MM DD'); 
   */