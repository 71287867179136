import { Component, Input, OnInit } from '@angular/core';
import { About } from '../../app.models';

@Component({
  selector: 'app-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.scss']
})
export class MissionComponent implements OnInit {

  @Input() about:About;

  constructor() { 
    this.about = new About();
  }

  ngOnInit() {
  }

}
