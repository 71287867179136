import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AddressPV, SendEmail, SendEmailMsg, Vehicle, Vehicle_Redux } from 'src/app/app.models';
import { AppService } from 'src/app/app.service';
import { MyErrorStateMatcher } from 'src/app/shared/classes/MyErrorStateMatcher';
import { emailValidator } from '../../utils/app-validators';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NotifierService } from 'angular-notifier';
import { send, setMaxListeners } from 'process';
import { setInterval } from 'timers';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() domain?:string;
  /**I.A */
  public ai:boolean = false;

  public lat: number = 40.678178;
  public lng: number = -73.944158;
  public zoom: number = 12; 
  private readonly notifier: NotifierService;
  
  public matcher = new MyErrorStateMatcher();
  public date:Date = new Date();
  public vehicle:Vehicle_Redux = new Vehicle_Redux();
  public emp:Object = {
        text:"AXL Comunicação",
        comp:"Comunicação",
        link:"https://axlcomunicacao.com.br/"
  };
  public mapStyles:any = [
    {
        "featureType": "all",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "saturation": 36
            },
            {
                "color": "#000000"
            },
            {
                "lightness": 40
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#000000"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 17
            },
            {
                "weight": 1.2
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#8b9198"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#323336"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#414954"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#2e2f31"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#7a7c80"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#242427"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#202022"
            },
            {
                "lightness": 29
            },
            {
                "weight": 0.2
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 18
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#393a3f"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#202022"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#393a3f"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#202022"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 19
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#202124"
            }
        ]
    }
  ];
  public feedbackForm: FormGroup;
  public subscribeForm: FormGroup;
  public readonly TIME:number = environment.domain.time;
    sucesso: string;
  
  constructor(private service:AppService,public notifierService: NotifierService,public formBuilder: FormBuilder,public appService:AppService) {
    this.notifier = notifierService;
   }

  ngOnInit() {
       /**Obtendo dominio compartilhado */
       const url = window.location.href;
       this.domain = this.service.getUrl(decodeURIComponent(url));/*Pegar dominio*/
    this.feedbackForm = this.formBuilder.group({ 
      email: new FormControl(null,[Validators.compose([Validators.required, emailValidator])]), 
      message: new FormControl(null,[Validators.required,Validators.minLength(5),Validators.maxLength(200)])
    });
    /////////////////////////////////////////////////
    this.subscribeForm = this.formBuilder.group({
      email: new FormControl(null,[Validators.compose([Validators.required, emailValidator])])
    })

    this.appService.getDomainVehicle_redux(this.domain).subscribe(data => {
        this.vehicle = data
        this.vehicle.phone = this.formatPhoneNumber(this.vehicle.phone)
    });
      
  }





  private formatPhoneNumber(phoneNumber: string): string {
    // Remove todos os caracteres não numéricos
    const cleaned = phoneNumber.replace(/\D/g, '');
    
    // Verifica se o número possui o formato correto
    const regex = /^(\d{2})(\d{4,5})(\d{4})$/;
    if (!regex.test(cleaned)) {
        throw new Error('Número de telefone inválido');
    }
    
    // Formata o número conforme o padrão (XX) XXXXX-XXXX
    const formatted = cleaned.replace(regex, '($1) $2-$3');
    
    return formatted;
}

/** */
  public onFeedbackFormSubmit(values:SendEmailMsg):void {
    if (this.feedbackForm.valid) {
      console.log(values);
      this.appService.sendEmail_VH("axl",new SendEmail(values.email,values.email+", enviou uma menssagem ",values.message))
      .subscribe( date => {
        this.sucesso = "Seu email foi enviado com sucesso para, "+ date.to
        this.showNotification("success",this.sucesso);
       }
       ,error => {
        let msg =  this.appService.getResponseError(error)
        this.showNotification("error",msg)
          
      });
    }
  }

  /**
   *  NEWSLETTER
   * @param values 
   */
  public onSubscribeFormSubmit(values:SendEmail):void {
    if (this.subscribeForm.valid) {
      console.log(values);
      this.appService.sendEmail_VH("axl",new SendEmail(values.email,"ESCREVESSE EM NOSSA NEWSLETTER "+this.domain,"GOSTARIA DE RECEBER A NEWSLETTER"))
      .subscribe( date => {
        this.sucesso = "Seu email foi enviado com sucesso para, "+ date.to
        this.showNotification("success",this.sucesso);
       },error => {
         let msg =  this.appService.getResponseError(error)
         this.showNotification("error",msg)
           
       });
    }
  }

  	/**
	 * Show a notification
	 *
	 * @param {string} type    Notification type
	 * @param {string} message Notification message
	 */
      public showNotification( type: string, message: string ): void {
		this.notifier.notify( type, message );
	}

}
