import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { AuthServiceService } from 'src/app/auth-service.service';

@Component({
  selector: 'app-toolbar2',
  templateUrl: './toolbar2.component.html'
})
export class Toolbar2Component implements OnInit {
  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>();
  domain: string;
  constructor(public appService:AppService,
    private accontService:AuthServiceService) { }

  public authUser: boolean = false;

  ngOnInit() {
    this.authUser = this.accontService.isUserLoggedIn(); 
    const url = window.location.href;
    this.domain = this.appService.getUrl(decodeURIComponent(url));
    
   }

  public sidenavToggle(){
    this.onMenuIconClick.emit();
  }
}