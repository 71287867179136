import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { CommentCreateUser, CommentCOMPLETE } from 'src/app/app.models';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-comment-viewbasic',
  templateUrl: './comment-viewbasic.component.html',
  styleUrls: ['./comment-viewbasic.component.scss']
})
export class CommentViewbasicComponent implements OnInit {

  public cm =  new CommentCreateUser();
  public nortify:boolean = false;

  @Input('title') title:string;
  @Output("event") rangerComment: EventEmitter<any> = new EventEmitter<any>();
  @Input('idnews') idnews:number;
  @Input('iduser') iduser:number;
  @Input('comments') reviews: CommentCOMPLETE[];
  @Input('propertyId') propertyId;
  public commentForm: FormGroup;
  private readonly notifier: NotifierService;

 constructor(public fb: FormBuilder,
    public appservice:AppService,
    public notifierService: NotifierService) {
      this.notifier = notifierService;
     }

  ngOnInit(): void {
  }

}
