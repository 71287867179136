

export class ContactVH{
    constructor(
        public mail?:string,
        public phone?:string,
        public address?:any
    ){}
}



export class Password{
    constructor(
        public password?:string
    ){}
}

export  class Pagination_{


    constructor(

        public lenght?:number,
        public size?:number,
        public page?:number

        
    ){

    }


}

export class DataIa{

    constructor(
     public data?:string
    ){

    }
}


export  class  ResponseIA{

    constructor(
        public text?:string,
        public promet?:string,
    ){

    }

}


export class NewsLine{
    constructor(
        public  id?:string,
        public  source?:string,
        public  aproved?:string,
        public  author?:string,
        public  title?:string,
        public  seo?:string,
        public  desc?:string,
        public  url?:string,
        public  urlToImage?:string,
        public  publishedAt?:string
    ){}
}


export class Email{
    constructor(
        public name?:string,
        public subject?:string,
        public msg?:string,
        public phone?:string,
        public email?:string,
    ){}
}

export class AuthConfig {
    constructor(
        public customers?:string,
        public user?:string,
        public provedor?:string,
	    public auth?:string,
	    public serial?:string,
	    public cnpj?:string,
        public gtag?:string,
        public config?:ConfigAuth
    ){}
}
export class ConfigAuth{
    constructor(
        public main?:Main
    ){}
}
export class Main{
    constructor(
        public color?:string,
        public navh?:boolean,
        public navv?:boolean
    ){}
}
export class LoginForm {
	constructor(
        public username?:string,
        public password?:string,
        public rememberMe?:boolean
		) {}
}

export class Login {
	constructor(
        public login?:string,
        public senha?:string
		) {}
}

export class Token {
	constructor(
        public pass?:any
		) {}
}


export class VehicleCT {
	constructor(
        public content?:any
		) {}
}


export class Source {
    constructor(
        public font?:string
		) {}
}
export class CommentVideo {
    constructor(
        public user:any,
        public desphoto:string,
        public name:string,
        public text:string,
        public comment:string,
        public plublicAt:Array<number>,
        public icon:string,
        public tooltip:string,
        public avatar:string,
        public author:string
		) {}
}
export class videoMedia {
	constructor(
        public  id?:number,
        public  user?:number,
        public  domain?:string,
        public 	title?:string,
        public 	desc?:string,
        public 	url?:string,
        public 	plublicAt?:Array<number>,
        public 	source?:Source,
        public 	comment?:Array<CommentVideo>
  ) {}
}
export class NewsIMG {
    constructor(
        public title?:string,
        public urlToImage?:string,
        public font?:string,
		) {}
}
export class Coluns {
    constructor(
        public  veihicle?:string,
        public  name?:string,
        public  url?:string,
        public  desphoto?:string,
        public  comment?:string,
        public  date?:Array<string>
		) {}
}

export class Filters{
    constructor(
        public  font?:string[],
        public  days?:number
	) {}
}

export class PageSize{
   constructor(
        public  size?:number,
        public pag?:number
	) {}
}



export class Property {
    constructor(public id: number,
                public title: string, 
                public desc: string,
                public propertyType: string,
                public propertyStatus: string[], 
                public city: string,
                public zipCode: string[],
                public neighborhood: string[],
                public street: string[],
                public location: Location,
                public formattedAddress: string,
                public features: string[],
                public featured: boolean,
                public priceDollar: Price,
                public priceEuro: Price,
                public bedrooms: number,
                public bathrooms: number,
                public garages: number,
                public area: Area,
                public yearBuilt: number,
                public ratingsCount: number,
                public ratingsValue: number,
                public additionalFeatures: AdditionalFeature[],
                public gallery: Gallery[],
                public plans: Plan[],
                public videos: Video[],
                public published: string,
                public lastUpdate: string,
                public views: number){ }
} 

export class AddressPV {
    constructor(public id?: number, 
                public about?:string,
                public desname?:string,
                public address?:any){ }
}

export class Faqs {
    constructor(public id: number, 
                public status?:number,
                public question?:string,
                public subject?:string,
                public answer?: string,
                public content?: string,
                public yes?:number,
                public no?:number,
                public dtregister?:Array<number>
                ){ }
}



export class Area {
    constructor(public id: number, 
                public value: number,
                public unit: string){ }
}

export class AdditionalFeature {
    constructor(public id: number, 
                public name: string,
                public value: string){ }
}

export class Location {
    constructor(public propertyId: number,
                public lat: number,
                public lng: number){ }
}

export class Price {
    public sale: number;
    public rent: number;
}


export class Gallery {
    constructor(public id: number, 
                public small: string,
                public medium: string,
                public big: string){ }
}

export class Plan {
    constructor(public id: number, 
                public name: string,
                public desc: string,
                public area: Area,
                public rooms: number,
                public baths: number,
                public image: string){ }
}

export class Video {
    constructor(public id: number, 
                public name: string,
                public link: string){ }
}

export class Pagination {
    constructor(public page: number,
                public perPage: number,
                public prePage: number,
                public nextPage: number,
                public total: number,
                public totalPages: number){ }
}




export class UserPost {

	constructor(
        public id?:number,
        public login?:string,
        public dt_naci?:string,
        public rg?:string,
        public cpf?:string,
        public sexo?:string,
        public background?:string,
        public desphoto?:string,
        public profession?:string,
        public date?:string,
        public admin?:string,
        public description?:string,
        public personDto?:Person,
        public des_name?:string,
        public dtophonesRes?:any,

		) {}
}
export class Vehicle_User{

	constructor(
        public address?: Address,
        public vehicle?:VehicleProfile,
        public user?: UserProfile
		) {}
}

export class VehicleProfile{

	constructor(
        public desphoto?:string,
        public email?:string,
        public phone?:string,
        public cnpj?:string,
        public desname?:string,
        public about?:About
		) {}
}

export class UserProfile{

	constructor(
        public name?:string,
        public email?:string
		) {}
}



export class Vehicle{

	constructor(
        public id?:number, 
        public desname?:string, 
        public segment?:string, 
        public desphoto?:string, 
        public about?:About,
        public nomefan?:string,
        public iduser?:number, 
        public idaddress?:string,
        public natjuridica?:string,
        public ctcadastra?:string,
        public port?:string,
        public cnpj?:string,
        public serial?:string,
        public phone?:string,
        public email?:string,
        public stservice?:number,
        public status?:number,
        public dtregister?:Array<number>,
		) {}
}

export class Vehicle_Redux{

	constructor(
        public desname?:string, 
        public desphoto?:string, 
        public about?:About,
        public user?:User_, 
        public phone?:string, 
        public email?:string, 
        public idaddress?:string,
        public cnpj?:string
		) {}
}

export class User_ {
    constructor(
        public id?: number,
        public vehicle?: any,
        public admin?: string,
        public login?: string,
        public sexo?: string,
        public rg?: string,
        public cpf?: string,
        public dt_naci?: string,
        public password?: any,
        public background?: any,
        public description?: any,
        public profession?: any,
        public insta?: string,
        public canal?: any,
        public face?: any,
        public twi?: string
    ) {}
}

export class Address {

	constructor(

        public  id?:number ,
        public  desnumber?:string, 
        public  desaddress?:string, 
        public  desstate?:string,  
        public  descity?:string, 
        public  tb_addresscol?:string, 
        public  descountry?:string,  
        public  desdistrict?:string,  
        public  deszipcode?:string, 
        public  dtregister?:Array<number>, 
     
		) {}
}

export class dtoPhone{

	constructor(
        public dd?:number,
        public telPhone?:string,
		) {}
}
export class Person {

	constructor(
        public  login?:string,
        public  dt_naci?:string,
        public  rg?:string,
        public  cpf?:string,
        public  person?:string,
        public  fist?:string,
        public  last?:string,
        public  email?:string,
        public  dtoPhoneRes?:Array<dtoPhone>,
        public  dtoPhoneCell?:Array<dtoPhone>
		) {}
}


export class UserCreate{

	constructor(

        public user?:UserData,
        public address?:Address,
		) {}
}

export class UserData {

	constructor(
        public id?:number,
        public password?:string,
        public cpassword?:string,
        public first?:string,
        public last?:string,
        public admin?:string,
        public login?:string,
        public dt_naci?:string | number,
        public sexo?:string,
        public background?:string,
        public desphoto?:string,
        public profession?:string,
        public date?:string,
        public permission?:string,
        public description?:string,
        public personDto?:Person,
        public des_name?:string,
        public terms?:boolean,
        public link?:string,
        public face?:string,
        public twi?:string,
        public insta?:string,
        public dtophonesRes?:Array<dtoPhone>,
        public dtophonesCell?:Array<dtoPhone>
		) {}
}

export class Galery{
	constructor(
       public low?:string,
       public large?:string,
       public small?:string,
       public gray?:string,
		) {}
}

export class Columns{
	constructor(
        public  id?:number,
        public  state?:number,
        public  v_name?:string,
        public  id_vehicle?:number,
        public  user?:UserData,
        public  url?:string,
        public  col_name?:string,
        public  col_desc?:string,
        public  rating_count?:number,
        public  rating_value?:number
		) {}
}


export class ColumnsComment{
	constructor(
        public  id?:number,
        public  state?:number,
        public  subject?:string,
        public  comment?:string,
        public  colum?:Columns,
        public  rating_count?:number,
        public  rating_value?:number
		) {}
}



export class NewsCP{

	constructor(

       public id?:number,
       public aproved?:string,
       public author?:string,
       public datePublic?:string,
       public title?:string,
       public description?:string,
       public url?:string, 
       public urlToImage?:string,
       public galery?:Galery,
       public publishedAt?:any,
       public ops?:string,
       public category?:string,
       public video?:string,
       public content?:string,
       public linksRelated?:Array<any>,
       public articledto?:any,



		) {}
}
//////////////////////////////////////////////////////////////////////////////////////
export class NewsCPagination{
	constructor(
           public articlesDTOCP?:ArticlesDTOCP
	
		) {}
}



export class ArticlesDTOCP{
	constructor(
           public content?:Content[]
	
		) {}
}


export class Content{
	constructor(
           public content?:NewsCP[]
	
		) {}
}
/////////////////////////////////////////////////////////////////////////////////////

export class NewsCPDTO{
	constructor(
           public articlesDTOCP?:NewsCP[],
	        public newsline?:NewsLine[],
            public category?:string[]
		) {}
}

export class NewsCPDTO_IA{
	constructor(
           public articlesDTOCP?:any
	
		) {}
}

export class Adverts{
	constructor(
     public  vechile?:number,
     public  featured?:boolean,
     public  icon?:string,
     public  title?:string,
     public  desc?:string,
     public  views?:number,
     public  propertyStatus?:any[],
     public  location?:LocationCordinate,
     public  gallery?:Gallery[],
     public  videos?:VideosCP[],
     public  address?:AddressAdverts,
     public  rating_count?:number,
     public  rating_value?:number,
     public  price_euro?:number,
     public  pricedollar?:number,
     public  pricebrl?:number,
     public  published?:number,
     public  additionalFeatures?:Addfreature[],
     public  lastUpdate?:string
	
		) {}
}


export class AddressAdverts{
	constructor(
        public descity?:string,
        public desaddress?:string,
        public desstate?:string,
        public desnumber?:number
    ){}
}

export class LocationCordinate{
	constructor(
        public lat?:number,
        public lng?:number
    ){}
}

export class Addfreature{
	constructor(
        public name?:number,
        public value?:number
    ){}
}


export class NewsCPCOMPLET{
	constructor(
       public id?:number,
       public aproved?:string,
       public author?:string,
       public datePublic?:string,
       public title?:string,
       public description?:string,
       public url?:string, 
       public urlToImage?:string,
       public videos?:VideosCP[],
       public galery?:Array<Galery>,
       public publishedAt?:any,
       public ops?:string,
       public category?:string,
       public content?:string,
       public genero?: Array<string>,
       public rating_value?: number,
       public rating_count?:number,
       public column?:number,
       public comment?:CommentNews,
       public hastags?: Array<string>,
       public linksRelated?:Array<string>,
       public articledto?:any
		) {}
}

export class SendEmail{

    constructor(

        public email?:string,
        public subject?:string,
        public content?:string,
    ){

    }

}
export class SendEmailCL{

    constructor(
        public name?:string,
        public email?:string,
        public message?:string,
        public subject?:string,
        public phone?:number
    ){

    }

}
export class SendEmailMsg{

    constructor(

        public email?:string,
        public message?:string,
    ){

    }

}

export class Sent{

    constructor(

        public neg?:number,
        public neu?:number,
        public pos?:number,
    ){

    }

}

export class NewsCPCOMPLET_AI{
	constructor(
       public id?:number,
       public aproved?:string,
       public author?:string,
       public datePublic?:string,
       public title?:string,
       public description?:string,
       public url?:string, 
       public urlToImage?:string,
       public videos?:VideosCP[],
       public galery?:Array<Galery>,
       public publishedAt?:any,
       public ops?:string,
       public category?:string,
       public content?:string,
       public  galeryImg?:Array<string>,
       public  dolly?:Array<string>,
       public  summary?:string,
       public  comments?:string,
       public  sentiment?:Sent,
       public  analyze?:string,
       public  likes?:number,
       public genero?: Array<string>,
       public rating_value?: number,
       public rating_count?:number,
       public column?:number,
       public comment?:CommentNews,
       public hastags?: Array<string>,
       public linksRelated?:Array<string>,
       public articledto?:any
		) {}
}

 
export class NewsAI{

	constructor(

       public id?:number,
       public aproved?:string,
       public author?:string,
       public datePublic?:string,
       public title?:string,
       public description?:string,
       public urlToImage?:string,
       public galery?:Galery,
       public galeryImg?:Array<any>,
       public publishedAt?:any,
       public category?:string,
       public dolly?:Array<string>,
       public content?:string,
       public summary?:string,
       public comments?:string,
       public sentiment?:Sent,
       public analyzes?:string,
       public copyright?:string,
       public likes?:number,
       public articledto?:NewsOne,
       public ratingsCount?:number,
       public ratingsValue?:number,
       public linksRelated?:Array<string>,
	) {}
}

export class NewsOne{
	constructor(
       public id?:number,
       public aproved?:string,
       public author?:string,
       public title?:string,
       public description?:string,
       public url?:string, 
       public st?:string, 
       public urlToImage?:string,
       public galery?:Galery,
       public publishedAt?:any,
       public source?:Source,
       public category?:string,
       public content?:string,
       public summary?:string,
       public sentiment?:Sent,
       public analyzes_fake?:string,
       public dolly?:Array<string>,
       public translater?:Translater | null,
       public copyright?:string,
       public analyzes?:string,
       public linksRelated?:Array<string>,
     ) {}
}

export class Translater{
    constructor(
        public pt?:Language,
        public es?:Language,
        public en?:Language
    ){
    }
}

export class Language{
    constructor(
        public title?:string,
        public content?:string,
    ){
    }
}

export class VideosCP{
	constructor(
           public link?:string,
           public name?:string
		) {}
}

export class CommentNews{
	constructor(
        public  id?: number,
        public  rate?: number,
        public  subject?: string,
        public  comment?: string,
        public  iduser?: number,
        public  idnews?: number,
        public  up?: number,
        public  back?: number,
        public  dtregister?:Array<number>
		) {}
}



export class CommentCreateUser{
	constructor(
        public  name?:string,
        public  text?:string,
        public  icon?:any,
        public  iduser?: number,
        public  idnews?: number,
        public  rate?: number,
        public  email?: string,
        public  subject?: string,
        public  comment?: string,
		) {}
}




export class CommentCOMPLETE{
	constructor(
        public  idcomment?: number,
        public  iduser?: number,
        public  idnews?: number,
        public  subject?: string,
        public  comment?: string,
        public  desphoto?: string,
        public  commentRespot?: string,
        public  icon?:string,
        public  tooltip?:string,
        public  avatar?:string,
        public  up?: number,
        public  back?: number,
        public  dtregister?:Array<number>
		) {}
}

export class About{
	constructor(
           public about?:string,
           public logline?:string,
           public mission?:any,
           public vision?:string
		) {}
}



export class Publicity{
	constructor(
           public id?:number,
           public link?:string,
           public title?:string,
           public icon?:string,
           public desphoto?:string,
           public content?:string,
           public company?:string,
           public tel?:string
		) {}
}
export class Videos{
	constructor(
           public low?:string,
           public small?:string,
           public big?:string
		) {}
}
export class Feature{
	constructor(
           public f1?:string,
           public f2?:string,
           public f3?:string
		) {}
}


export class Shopping{
	constructor(
        public id_vehicle?:number,
        public id_user?:number,
        public id_shoprod?:number,
        public company?:string,
        public status?:string,
        public  title?:string,
        public description?:string,
        public content?:string,
        public category?: string,
        public url?:string,
        public gallery?:Array<Galery>,
        public video?:Array<Videos>,
        public feature?:Array<Feature>,
        public price_euro?: number,
        public price_dollar?: number,
        public price_brl?:number,
        public published?:Array<number>,
        public icon?:string,
        public rating_count?:number,
        public rating_value?:string
		) {}
}


