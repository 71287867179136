import { Component, Input, OnInit } from '@angular/core';
import { AppService} from 'src/app/app.service';
import { AppSettings, Settings } from 'src/app/app.settings';
import { NewsCPDTO } from '../../app.models';


export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}



@Component({
  selector: 'app-grids-media-news',
  templateUrl: './grids-media-news.component.html',
  styleUrls: ['./grids-media-news.component.scss']
})
export class GridsMediaNewsComponent implements OnInit {

  @Input() newsAll: NewsCPDTO;
  @Input() viewType: string = "grid";
  @Input() grids: number;
  @Input() viewColChanged: boolean = false; 
  @Input() fullWidthPage: boolean = true;   
  public column:number = 4;
  public menuItems: any[];

  public tiles: Tile[] = [
    {text: 'One', cols: 2, rows:2, color: 'lightblue'},
    {text: 'Two', cols: 1, rows: 2, color: 'lightgreen'},
    {text: 'Four', cols: 1, rows: 2, color: '#DDBDF1'},
    {text: 'Three', cols: 1, rows: 2, color: 'lightpink'},
    {text: '9', cols: 1, rows: 2, color: 'lightpink'},
    {text: '6', cols: 1, rows: 2, color: 'lightpink'},
    {text: 'Three', cols: 1, rows: 2, color: 'lightpink'},
    {text: '5', cols: 1, rows: 2, color: 'lightpink'},
    {text: '6', cols: 1, rows: 2, color: 'lightpink'},
    {text: '7', cols: 1, rows: 2, color: 'lightpink'},
    {text: '8', cols: 1, rows: 2, color: 'lightpink'},
  ];

  public settings: Settings;
  domain: string;
  constructor(public appSettings:AppSettings, public appService:AppService) {
    this.settings = this.appSettings.settings;
    this.menuItems = this.appService.ROUTES.filter(menuItem => menuItem);
    
  }


  ngOnInit(): void {
    this.domain = this.appService.domain_;
  }
  public slideConfig2 = {
    slidesToShow: 3, // Defina o número de slides a serem exibidos
    slidesToScroll: 1,
    infinite: true,
    dots: false,
    arrows: true,
    prevArrow: '<button  type="button" class="slick-prev">&#10094;</button>',
    nextArrow: '<button type="button" class="slick-next">&#10095;</button>',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          infinite: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          infinite: true,
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

  public getStatusBgColor(status){
    switch (status) {
      case 1:
        return '#558B2F';  
      case 2:
        return '#1E88E5'; 
      case 3:
        return '#009688';
      case 4:
        return '#FFA000';
      case 5:
        return '#F44336';
      case 6:
        return '#000';
      default: 
        return '#01579B';
    }
  }

  public addToCompare(){
   // this.appService.addToCompare(this.property[0], CompareOverviewComponent, (this.settings.rtl) ? 'rtl':'ltr'); 
  }

  public onCompare(){
  //  return this.appService.Data.compareList.filter(item=>item.id == this.property[0].id)[0];
  }

  public addToFavorites(){
   // this.appService.addToFavorites(this.property[0], (this.settings.rtl) ? 'rtl':'ltr');
  }

  public onFavorites(){
   // return this.appService.Data.favorites.filter(item=>item.id == this.property[0].id)[0];
  }
  
}
