import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserData } from '../../app.models';

@Pipe({
    name: 'safe'
  })
  export class SafePipe implements PipeTransform {
  
    private readonly regra:string  = "watch?v=";

    constructor(private sanitize:DomSanitizer){
  
    }
  
    transform(url:string): unknown {
        if(url != undefined){
           url = url.replace(this.regra,"embed/");
        }
      return  this.sanitize.bypassSecurityTrustResourceUrl(url);
    }
    
}
  