import { Component, Input, OnInit } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription, filter, map } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { AppSettings, Settings } from 'src/app/app.settings';
import { CommentNews } from '../../app.models';

@Component({
  selector: 'app-card-comment-page',
  templateUrl: './card-comment-page.component.html',
  styleUrls: ['./card-comment-page.component.scss']
})
export class CardCommentPageComponent implements OnInit {

  @Input() comment:Array<CommentNews>;

  watcher: Subscription;
  public viewType: string = 'grid';
  public viewCol: number = 25;
  public count: number = 8;
  public settings: Settings;
  array = [1,2,3];
  
 
  constructor(public appSettings:AppSettings, public appService:AppService, public mediaObserver: MediaObserver) {
    this.settings = this.appSettings.settings;

    this.watcher = mediaObserver.asObservable()
    .pipe(filter((changes: MediaChange[]) => changes.length > 0), map((changes: MediaChange[]) => changes[0]))
    .subscribe((change: MediaChange) => {
      // console.log(change)
      if(change.mqAlias == 'xs') {
        this.viewCol = 100;
      }
      else if(change.mqAlias == 'sm'){
        this.viewCol = 50;
      }
      else if(change.mqAlias == 'md'){
        this.viewCol = 33.3;
      }
      else{
        this.viewCol = 25;
      }
    });

  }

  ngOnInit() {


    
  }


}
