import { Component, Input, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { AppService } from 'src/app/app.service';
import { AuthServiceService } from 'src/app/auth-service.service';

declare var Drift:any;
declare var $:any;
declare var window:any;


export interface Share{
  text?:string;
  title?:string;
  url?:string;
}

/**
 * Dados share link
 */
 const DATA_SHARE:Share = {
   text:"PORTAL Plano-Teste",
   title:"Portal de Teste",
   url:"https://portal.axl.app.br/home/"
 };

@Component({
  selector: 'app-cogs',
  templateUrl: './cogs.component.html',
  styleUrls: ['./cogs.component.scss']
})
export class CogsComponent implements OnInit {

  domain:string;
  id:number=1;
  public share:Share = DATA_SHARE;
  public authUser: boolean = false;
  public user_role:string="";
  private readonly notifier: NotifierService;
  public messages:any[]=[];

  constructor( private notifierService: NotifierService,private accontService:AuthServiceService,private service:AppService) { 
    this.notifier = notifierService;
    try {
      Drift.getMenssageDrift();
      Drift.drifthide();
    } catch (error) {
       console.error("erro chat drift")
       let erro = "erro chat drift";
       this.showNotification(erro,"error");
      }
    
  }

  ngOnInit(): void {
    this.domain = this.service.domain_;
    this.authUser = this.accontService.isUserLoggedIn(); 
    $("#scrollUp").hide();
    try{
      new window.VLibras.Widget('https://vlibras.gov.br/app');
    }catch(error) {
      console.error("Erro vr libras");
      let erro = "Erro vr libras";
      this.showNotification(erro,"error");
    }
    this.sharelink(this.share);
    function toggleFAB(fab){
      if(document.querySelector(fab).classList.contains('show')){
        document.querySelector(fab).classList.remove('show');
      }else{
        document.querySelector(fab).classList.add('show');
      }
    }
    
    document.querySelector('.fab .main').addEventListener('click', function(){
      toggleFAB('.fab');
    });
    
    document.querySelectorAll('.fab ul li button').forEach((item)=>{
      item.addEventListener('click', function(){
        toggleFAB('.fab');
      });
    });
  }

  /**
   * GetChat bot visible
   * @returns Void
   */
   public  getBot():void{
     $("#btn-bot").click(function(){
        $("#scrollUp").hide();
        Drift.driftShow();
     });
  }


  public Arrow(){
    $("#btn-arrow").click(function(){
      $("#scrollUp").show();
      Drift.drifthide();
   });
  }

   /**
    * SHARE LINK
    * @param shar 
    */
   /**share-link */
   public sharelink(shar?:Share){
 
    let erro:any = document.querySelector('.error');
    
    erro.style.display = "none";

    let cod = ".btn-sh-" + this.id;

    document.querySelector(cod).addEventListener('click',async () => {
      if(navigator.share){
         try{
            await navigator.share({
              title:shar?.title,
              text:shar?.text,
              url:shar?.url + this.domain
            })

            erro.textContent = "MDN shared!";

         }catch(erro:any){

            erro.textContent = "Info"+erro;     

            this.showNotification(erro.textContent,"error");
            
        }
      }else{

        erro.style.display = "flex";

        let sh:any = document.querySelector('.share');

        sh.style.background = "#b294b97a"; 

        erro.textContent = "Navigator share não disponível, tente em um celular :)";  

        this.showNotification(erro.textContent,"error");
         
      }
  
    });  
   }
	/**
	 * Show a notification
	 *
	 * @param {string} type    Notification type
	 * @param {string} message Notification message
	 */
  public showNotification( type: string, message: string ): void {
		this.notifier.notify( type, message );
	}


}
