import { Component, OnInit } from '@angular/core';
import { AppSettings, Settings } from 'src/app/app.settings';

@Component({
  selector: 'app-monthly',
  templateUrl: './monthly.component.html',
  styleUrls: ['./monthly.component.scss'] 
})
export class MonthlyComponent implements OnInit {
  public settings: Settings;
    //10% de desconto
    private percent:number = 0.10;
    price_1: number=49;
    price_2: number=99;
    public items = [
    {
      name: 'INICIAL', 
      price:  Math.round(this.price_1),
      content:{
        item: [
          "Content management system CMS",
          "Publicidades ADS e Google Analytics",
          "Hospedagem Amazon AWS",
          "Link Bio Grátis veja mais",
          "Jornal em PDF",
          "Sistema para anunciantes",
          "Sistema de (Shopping)",
          "Classificados",
          "Previsão do Tempo (Real Time)",
          "Publicidades",
          "Seguro de 1 ano contra exclusão de dados.",
          "Configuração do Tema",
          "Recursos de Inteligência Artificial"
        ]
      },
      desc: 'Plano recomendado para clientes',
      profile: '1',
      favority: '0', 
      share: '0', 
      fidelis: '0',
      url:"https://axl.app.br/register/type/user" 
    },
    {
      name: 'BÁSICO', 
      price:  Math.round(this.price_2),
      content: {
        item: [
          "Content management system CMS",
          "Auto Post",
          "Publicidades ADS e Google Analytics",
          "Hospedagem Amazon AWS",
          "Link Bio Grátis veja mais",
          "Jornal em PDF",
          "Sistema para anunciantes",
          "Sistema de (Shopping)",
          "Classificados",
          "Fact-Cheking Automatizado",
          "Previsão do Tempo (Real Time)",
          "Publicidades",
          "Seguro de 1 ano contra exclusão de dados.",
          "Internacionalização do conteúdo em três línguas",
          "Tradução total do conteúdo",
          "Recursos de Inteligência Artificial",
          "Configuração do Tema"
        ]
      },
      desc: 'Plano recomendado para clientes',
      profile: '1',
      favority: '0', 
      share: '0', 
      fidelis: '0',
      url:"https://axl.app.br/register/type/user" 
    }

  ]


  constructor(public appSettings:AppSettings) { 
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
  }

}
