import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { AuthServiceService } from 'src/app/auth-service.service';
import { AppService } from '../../app.service';
import { Password, UserData } from 'src/app/app.models';

@Component({
  selector: 'app-lock-screen',
  templateUrl: './lock-screen.component.html',
  styleUrls: ['./lock-screen.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LockScreenComponent implements OnInit {
  
  public user:UserData = new UserData();
  public date:any = new Date();
  public timerInterval:any;
  public form: FormGroup;
  public authUser: boolean = false;
  domain: string;

  constructor(    private activatedRoute: ActivatedRoute,public appService:AppService,public fb: FormBuilder, public router:Router,  public accontService:AuthServiceService,) { }

  ngOnInit() {
    this.authUser = this.accontService.isUserLoggedIn();
    this.activatedRoute.queryParams.subscribe(params => {
      this.domain = params['dm'];
    });
    if(this.authUser === true){
      this.appService.getByUser(this.appService.getUser()).subscribe(data => this.user = data)
    }
    this.timerInterval = setInterval(() => {
      this.date = new Date();
    }, 1000);
    this.form = this.fb.group({ 
      password: [null, Validators.compose([Validators.required, Validators.minLength(3)])]
    });
  }

  ngAfterViewInit(){
    document.getElementById('preloader').classList.add('hide');
  }

  ngOnDestroy(){
    clearInterval(this.timerInterval);
  }

  public onSubmit(values:Password):void {
    if (this.form.valid) {
      console.log(values.password);
     // this.router.navigate(['/']);
    }
  }

}
