import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-cp-sm',
  templateUrl: './dialog-cp-sm.component.html',
  styleUrls: ['./dialog-cp-sm.component.scss']
})
export class DialogCpSmComponent{

  constructor() {}



}
