import { Component, Input, OnInit } from '@angular/core';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { AppService } from 'src/app/app.service';
import { Publicity } from '../../app.models';
import { Tile } from '../manchete/manchete.component';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  @Input() domain:string;
  public publicitys:Publicity[] = [];
  public config: SwiperConfigInterface = { };
  constructor(public appService:AppService) { }
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };
  /**Error */
  public error:string;
  /**Skell */
  public isLoading: boolean = true;
  
  public tiles: Tile[] = [
    {text: 'One', cols: 2, rows:2, color: 'lightblue'},
    {text: 'Two', cols: 1, rows: 2, color: 'lightgreen'},
    {text: 'Four', cols: 1, rows: 2, color: '#DDBDF1'}
  ];

  ngOnInit() {
    this.isLoading = true;
    this.appService.getPublicityDomain(this.domain)
    .subscribe(data => {
      this.publicitys  = data
    },error => {
       
         this.error = error
    },
    () => {
      // Callback para execução após o carregamento dos clientes ser concluído
      this.isLoading = false // Marcar como não carregando após a conclusão da solicitação
    });
  }

  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 1,
      spaceBetween: 0,       
      keyboard: true,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,        
      loop: true,
      preloadImages: false,
      lazy: false,  
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      speed: 500,
      effect: "slide",
      breakpoints: {
        480: {
             slidesPerView: 1
        },
        740: {
             slidesPerView: 2,
        },
        960: {
             slidesPerView: 3,
        }
       }
    }
  }

}