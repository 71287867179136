import { Component, Input, OnInit } from '@angular/core';
import { AppService, Data } from '../../app.service';
import { Publicity } from '../../app.models';
import { AppSettings, Settings } from 'src/app/app.settings';
import { BehaviorSubject, Observable } from 'rxjs';
import { Tile } from '../manchete/manchete.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-get-in-touch',
  templateUrl: './get-in-touch.component.html',
  styleUrls: ['./get-in-touch.component.scss']
})
export class GetInTouchComponent implements OnInit {
  
  @Input() domain:string;
 /**Cache */
  public cache:BehaviorSubject<any> = new BehaviorSubject(null); 
  public error:string;
  public isLoading:boolean = true;
  lastAdDisplayTime: number = 0;
  public response:Publicity | Observable<Publicity> = new Publicity();
  public settings: Settings;
  displayModal: boolean=true;
  constructor(private service:AppService,public appSettings:AppSettings) { 
    this.settings = this.appSettings.settings;
  }
  
  public tiles: Tile[] = [
    {text: 'One', cols: 1, rows:1, color: 'lightblue'},
  ];

  ngOnInit() {
    if(this.domain == null || this.domain == undefined){
      const session = this.service.loadSession();
      this.domain = session.dm;
    }
    if (this.cache.getValue() != null) {
      this.response = new Observable<Publicity>((res) => {
        res.next(this.cache.getValue());
      });
    } else {
      this.service.getPublicityDomain(this.domain)
        .subscribe(data => {
          if (data != undefined && data.length > 0) {
            let rand = this.randPublicity(data.length)
            this.cache.next(data[rand]);
            this.response = this.cache.getValue();
  
            for (let index = 0; index < data.length; index++) {
              data[index].tel = this.formatPhoneNumber(data[index].tel);
            }
          
          }
          this.isLoading = false
        },error => {

           this.error =  error + " Criar uma registro"+" "+  "<a href="+"'"+environment.register.adverts+"'"+" target='_black'>"+"Criar um registro"+"</a>"

        });
    }
    this.shouldDisplayAd();
  }


private shouldDisplayAd():void {
    const currentTime = Date.now();
    const fiveMinutes = 5 * 60 * 1000; // 5 minutes in milliseconds
  
    if (currentTime - this.lastAdDisplayTime >= fiveMinutes) {
      this.lastAdDisplayTime = currentTime;
      this.displayModal = true;
    } else {
      this.displayModal = false;
    }
  }
  
  /**
   * Select Random
   * @param tam 
   * @returns 
   */
  private randPublicity(tam:number){
 
    return  Math.ceil((Math.random() * (tam-1)));
  }

  private formatPhoneNumber(phoneNumber: string): string {
    // Remover todos os caracteres que não são dígitos
    var cleaned = ('' + phoneNumber).replace(/\D/g, '');
  
    // Verificar se o número tem um formato válido
    var match = cleaned.match(/^(\d{2})(\d{4,5})(\d{4})$/);
    if (match) {
      // Formatar o número como (XX) XXXXX-XXXX
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
  
    // Retornar o número original se não for possível formatar
    return phoneNumber;
  }

}
