import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-circle-news',
  templateUrl: './circle-news.component.html',
  styleUrls: ['./circle-news.component.scss']
})
export class CircleNewsComponent implements OnInit {
  public array:Array<any> = [
    {
      "font": "Foxnews",
      "url": "../../../assets/images/instagram/foxnews.jpg",
      "link": "https://www.instagram.com/foxnews/",
    },
    {
      "font": "awj",
      "url": "../../../assets/images/instagram/wsj.jpg",
      "link":"https://www.instagram.com/wall.street.journal/",
    },
    {
      "font": "caesecia.pet",
      "url": "../../../assets/images/instagram/caescia.jpg",
      "link": "https://www.instagram.com/caesecia.pet/",
    },
    {
      "font": "Jpost",
      "url": "../../../assets/images/instagram/jpost.jpg",
      "link": "https://www.instagram.com/thejerusalem_post/",
    },
    {
      "font": "Trump",
      "url": "../../../assets/images/instagram/trump.jpg",
      "link": "https://www.instagram.com/realdonaldtrump/",
    },
    {
      "font": "Foxnews",
      "url": "../../../assets/images/instagram/tmz.jpg",
      "link": "https://www.instagram.com/tmz_tv/",
    },
    {
      "font": "dailymail",
      "url": "../../../assets/images/instagram/daily.jpg",
      "link": "https://www.instagram.com/dailymail/",
    },
    {
      "font": "borisjohnsonuk",
      "url": "../../../assets/images/instagram/boris.jpg",
      "link": "https://www.instagram.com/borisjohnsonuk/",
    },
    {
      "font": "jairmessiasbolsonaro",
      "url": "../../../assets/images/instagram/bolsonaro.jpg",
      "link": "https://www.instagram.com/jairmessiasbolsonaro",
    },
    {
      "font": "forbesbr",
      "url": "../../../assets/images/instagram/forbs.jpg",
      "link": "https://www.instagram.com/forbesbr/",
    },
    {
      "font": "adorocinema",
      "url": "../../../assets/images/instagram/adoro.jpg",
      "link": "https://www.instagram.com/adorocinema/",
    },
  
    {
      "font": "caesecia.pet",
      "url": "../../../assets/images/instagram/caescia.jpg",
      "link": "https://www.instagram.com/caesecia.pet/",
    }, 
];
  constructor() { }

  ngOnInit(): void {
  }
/**
   * Remover cicle  ng-slix
   * @param fruit 
   * @param id 
   */
 remove(font: string,id:number): void {
  const index = this.array[id].font.indexOf(font);
  if (index >= 0) {
    this.array.splice(index, 1);
  }
}
  public slideConfig2 = {
    infinite: false,
    slidesToShow: 8,
    slidesToScroll: 8,
    autoplay:true,
    arrows: true, 
    speed: 300,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };
}
