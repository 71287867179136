import { environment, loadSession } from 'src/environments/environment';
import { Menu } from './menu.model';


var  domain;
async function loadDomain() {
    let session = await loadSession();
    return session.dm;
}

export async function horizontalMenuItems(): Promise<Array<Menu>> {
    domain = await loadDomain();
      return [
            new Menu (1, 'NAV.HOME', '/home/'+domain, null, null, false, 0),
            new Menu (40, 'NAV.PAGES', null, null, null, true, 0),
            new Menu (41, 'NAV.COLUMNISTS', '/agents/'+domain, null, null, false, 40), 
            new Menu (80, 'NAV.ALL',"/", null, null, false, 3),
            new Menu (43, 'NAV.LOGIN', '/login', null, null, false, 40), 
            new Menu (44, 'REGISTER', '/register', null, null, false, 40), 
            new Menu (45, 'FAQs', '/faq', null, null, false, 40),
            new Menu (46, 'NAV.PRICING', '/pricing', null, null, false, 40), 
            new Menu (47, 'NAV.TERMS_CONDITIONS', '/terms-conditions', null, null, false, 40),  
            new Menu(60, 'NAV.CONTACT', `/contact/`+domain, null, null, false, 0),
            new Menu (70, 'ABOUT', '/about/'+domain, null, null, false, 0),   
            new Menu (140, 'NAV.OTHERS', null, null, null, true, 40),
            new Menu (141, 'NAV.EXTERNAL_LINK', null, environment.companys.site, '_blank', false, 140),  
            new Menu (145, "NAV.WORLD", "/",null,null, true, 0),  
            new Menu (144, "NAV.INTERNACIONAL", "/home/"+domain+"/internacional/"+domain,null,null, false, 145) 
   ]
}

//  new Menu (48, 'MARKETPLACE', '/landing', null, null, false, 40), 

export async function verticalMenuItems(): Promise<Array<Menu>> {
    domain = await loadDomain();
      return [
        new Menu (1, 'NAV.HOME', '/home/'+domain, null, null, false, 0), 
        new Menu (40, 'NAV.PAGES', null, null, null, true, 0),
        new Menu (41, 'NAV.COLUMNS', '/agents/'+domain, null, null, false, 40), 
        new Menu (43, 'NAV.LOGIN', '/login', null, null, false, 40), 
        new Menu (44, 'REGISTER', '/register', null, null, false, 40),   
        new Menu (45, 'FAQs', '/faq', null, null, false, 40),
        new Menu (46, 'NAV.PRICING', '/pricing', null, null, false, 40), 
        new Menu (47, 'NAV.TERMS_CONDITIONS', '/terms-conditions', null, null, false, 40),  
        new Menu (61, 'NAV.CONTACT', '/contact/'+domain, null, null, false, 0),   
        new Menu (70, 'ABOUT', '/about/'+domain, null, null, false, 0),   
        new Menu (144, "NAV.INTERNACIONAL", "/home/"+domain+"/internacional/"+domain,null,null, false, 145) 
   ]
}





//new Menu (140, 'NAV.MENU_ITEM', null, null, null, true, 0),
//new Menu (141, 'NAV.MENU_ITEM', null, null, null, true, 140),