import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { AuthServiceService } from '../auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class NoopInterceptorService implements HttpInterceptor {


  constructor(private accont:AuthServiceService){ 
  }

intercept(req: HttpRequest<any>, next: HttpHandler){
  const token = this.accont.getAuthorizationToken()
  let request:HttpRequest<any> = req;

  if(token){
     request = req.clone({
         headers:req.headers.set(`Authorization`,`Bearer ${token}`)
     });
  }

  return next.handle(request)
  .pipe(
      catchError(this.handleError)
  );

}

handleError(error: HttpErrorResponse) {
  let errorMessage = 'Ocorreu um erro';
  if (error.error instanceof ErrorEvent) {
    // Erro no lado do cliente
    errorMessage = error.error.message;
  } else {
    // O servidor retornou um código de status de erro
    errorMessage = `Código do erro ${error.status}, Mensagem: ${error.error.error}`;
  }
  // Você pode fazer o que quiser com a mensagem de erro aqui
  console.error(errorMessage);
  return throwError(errorMessage);
}


}
