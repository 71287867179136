// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const arq = require('../assets/data/auth/auth-config.json'); 

interface SessionData {
  dm: string;
}

//http://localhost:8081/provedor/serve
//https://dmydlzz4jx2i2.cloudfront.net/provedor

export const environment = {
  production: false,
  url: '',
  API: JSON.stringify(arq),
  serve:'https://dmydlzz4jx2i2.cloudfront.net/provedor',
  manchete:["SEVERAL"],
  genres: [
  "POLITIC",
  "GAME",
  "SCIENCE",
  "SPORT",
  "CELEBRITIES",
  "MUSIC",
  "HEALTH",
  "CINEMA",
  "AUTOMOBILES"],
  days:60,
  valid:"https://triunfo.pe.gov.br/pm_tr430/wp-content/uploads/2018/03/sem-foto.jpg",
  pagination:10,
  searchDefault:{
    day:60,
    tam:10,
    dateIn:"2021-04-04"
  },
  companys:{
    company:"AXL Comunicação LTDA",
    site:"https://www.axlcomunicacao.com.br",
    ecommerce:"https://www.vendas.axlcomunicacao.com.br",
    cnpj:"47.786.943/0001-55",
    slogan:"Solução e Eficiência ao seu alcance!",
    link:"https://docs.google.com/forms/d/e/1FAIpQLSfHMzjUqtm4TPeApnaxapXp3VhpOGqbJ4j3FJ2Yt4n2NjjpgA/viewform",
    tagline:"Provedores de conteúdo e outros serviços automatizados, comece a empreender a qualquer momento com AXL.",
    demos:"https://www.axlcomunicacao.com.br/beta-plano-pro/"
  },
  patch:"home",
  register:{
    adverts:"https://axl.app.com.br/admin/dominio/adverts"
  },
  domain:{
    time:10
  },
  google:'UA-210292216-1',
  bio:"https://axlbio.provedorinteligente.tech/home",
  account:"https://axl.app.br/register/account"
};

// Função para verificar e carregar a sessão
export  function loadSession(): SessionData {
  // Verifica se há uma sessão existente
  const sessionStr = localStorage.getItem('se_dm');
  // Se a sessão existir, carrega-a e a retorna
  if (sessionStr) {
    const sessionData: SessionData = JSON.parse(sessionStr);
    return sessionData;
  }
}
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

